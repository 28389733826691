
export default [
    {
        path: '/categories',
        name: 'categories',
        component: () => import('./page/List.vue')
    },
    {
      path: '/categories/create',
      name: 'categories.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/categories/:categoryId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'categories:edit',
    },
]