import {
  SET_TEMPLATE_DROPDOWN
} from './mutation-type';

import {changeDataToDropDownFormat} from '@/utils'

const state = {
    template_types_dropdown: [],
};

const getters = {
    getTemplateTypesDropdown : (state) => state.template_types_dropdown,
};

const actions = {
    fetchAndSetTemplateTypesDropdown({ commit }) {
      let data = [
        {name: 'Page', id: 'page'},
        {name: 'Article', id: 'article'},
        {name:'Info', id:'info'}
      ];
            commit(SET_TEMPLATE_DROPDOWN, changeDataToDropDownFormat(data));
    }
};

const mutations = {
    [SET_TEMPLATE_DROPDOWN](state, records ) {
        state.template_types_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
