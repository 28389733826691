
/**
 * Get list of amenities
 * 
 * @param {object} state 
 */
export const getSeoPages = (state) => {
    return state.seo_pages;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getAmenityTypes = (state) => {
    return state.types;
}

export const getSeopageableTypeDropdown = (state) => {
    return state.seopageable_type_dropdown;
}

export const getImages = (state) => {
    return state.amenityImages;
}

export const getDropdownObj = (state) => {
    return state.dropdown;
}