import template from 'lodash/fp/template';
import {
    FETCH_PACKAGE_REGIONS,
    STORE_PACKAGE_REGION,
    FETCH_PACKAGE_REGION_DETAILS,
    UPDATE_PACKAGE_REGION,
    DELETE_PACKAGE_REGION
} from './api';

import axios from 'axios';

/**
 * Fetch package regions
 */
export const fetchPackageRegions = (query) => axios.get(template(FETCH_PACKAGE_REGIONS)({ query }));

/**
 * Store package regions
 */
export const storePackageRegion = (data) => axios.post(STORE_PACKAGE_REGION, data);

export const fetchAllPackageRegions = () => axios.get(STORE_PACKAGE_REGION);


/**
 * Fetch package region by id.
 * 
 * @param {Integer} id 
 */
export const fetchPackageRegionById = (id) => axios.get(template(FETCH_PACKAGE_REGION_DETAILS)({ id }));

/**
 * Update package region.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updatePackageRegion = (id, data) => axios.patch(template(UPDATE_PACKAGE_REGION)({ id }), data);


export const deletePackageRegion = (id) => axios.delete(template(DELETE_PACKAGE_REGION)({ id }))
