
export default [
    {
        path: '/features',
        name: 'features',
        component: () => import('./page/List.vue')
    },
    {
      path: '/features/create',
      name: 'features.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/features/:featureId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'features:edit',
    },
]