import {
    SET_PROPERTY_TYPES_DROPDOWN
} from './mutation-type';

import {fetchPropertyTypes} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    property_types_dropdown: [],
};

const getters = {
    fetchPropertyTypesDropdown : (state) => state.property_types_dropdown,
};

const actions = {
    fetchAndSetPropertyTypesDropdown({ commit }) {
        fetchPropertyTypes().then((response) => {
            commit(SET_PROPERTY_TYPES_DROPDOWN, changeDataToDropDownFormat(response.data.data));
        });
    }
};

const mutations = {
    [SET_PROPERTY_TYPES_DROPDOWN](state, records ) {
        state.property_types_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
