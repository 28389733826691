import {
    VALIDATION_ERROR,
    CLEAR_VALIDATION_ERROR
} from './mutation-type';

const state = {
    validation: {},
};

const getters = {
    getValidationErrors : (state) => state.validation,
};

const actions = {

    setValidationErrors({ commit }, errors) {
        commit(VALIDATION_ERROR, errors);
    },

    clearValidationErrors({ commit }) {
        commit(CLEAR_VALIDATION_ERROR, []);
    },

};

const mutations = {
    [VALIDATION_ERROR](state, errors ) {
        state.validation = errors;
    },
    [CLEAR_VALIDATION_ERROR](state, empty ) {
        state.validation = empty;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
