
export default [
    {
        path: '/bookings',
        name: 'bookings',
        component: () => import('./page/List.vue')
    },
    {
      path: '/bookings/:bookingId/view',
      component: () => import('./page/View.vue'),
      name: 'bookings.view',
    },
]