export default {
  packages: {
    data: [],
    pagination: {
      last_page: 1,
      per_page: 10,
      current_page: 1,
    },
  },
  search: {
    query: {
      id: "",
      name: "",
    },
  },
  form_dropdown: {
    region_id: {},
    vendor_id: {},
    activity_id: {},
    departure_type: {},
    package_type: {},
    listing_type: {},
    tag: {},
  },
  package_images: [],
  geo: {
    available_features: [],
  },
  form: {
    name: "",
    slug: "",
    vendor_id: "",
    region_id: "",
    activity_id: "",
    departure_type: "",
    package_type: "",
    listing_type: "",
    price_per_person: "",
    guide_language: "",
    guiding_method: "",
    max_altitude: "",
    location_start_from: "",
    location_end_to: "",
    age: "",
    google_map: "",
    overview: "",
    highlights: "",
    includes: "",
    excludes: "",
    good_to_know: "",
    cancellation: "",
    accommodation: "",
    duration: "",
    discount_total_no: null,
    discount_percentage: null,
    is_sponsored: true,
    status: true,
    full_payment: false,
    images: [],
    meta_page_title: "",
    meta_keywords: "",
    meta_description: "",
    position: "",
    available_features: [],
    tag: ""
  },

  itenaries: {
    data: [],
  },

  itenaryForm: {
    itenaryId: "",
    duration: "",
    accommodation: "",
    title: "",
    description: "",
  },

  image_url: "",

  departures: {
    data: [],
    pagination: {
      last_page: 1,
      per_page: 10,
      current_page: 1,
    },
  },

  departureForm: {
    departure_date: "",
    discount_percent: 0,
    offer_title: "",
    status: 1,
  },
  tags: [
    { label: "Sponsored", value: "Sponsored" },
    { label: "Best Value", value: "Best Value" },
    { label: "Best Seller", value: "Best Seller" },
    { label: "Top Rated", value: "Top Rated" },
    { label: "Top Pick", value: "Top Pick" },
    { label: "Exclusive Offer", value: "Exclusive Offer" },
  ],
};
