export const SET_BLOGS   = 'SET_BLOGS';
export const SET_PACKAGES   = 'SET_PACKAGES';
export const SET_HOMESTAYS   = 'SET_HOMESTAYS';
export const SET_HOTELS   = 'SET_HOTELS';

export const SET_CATEGORIES   = 'SET_CATEGORIES';

export const SET_BLOG_FORM   = 'SET_BLOG_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';