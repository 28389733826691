
export default [
    {
        path: '/homestays',
        name: 'homestays',
        component: () => import('./page/List.vue')
    },
    {
      path: '/homestays/create',
      name: 'homestays.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/homestays/:homestayId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'homestays.edit',
    },
    {
      path: '/homestays/:homestayId/view',
      component: () => import('./page/View.vue'),
      name: 'homestays.view',
    },
    {
      path: '/homestays/:homestayId/rooms/create',
      component: () => import('./rooms/page/Create.vue'),
      name: 'homestays.rooms.create',
    },
    {
      path: '/homestays/:homestayId/rooms/:roomId/edit',
      component: () => import('./rooms/page/Edit.vue'),
      name: 'homestays.rooms.edit',
    }
]