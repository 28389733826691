import {
    fetchPackageRegions,
    fetchAllPackageRegions,
    storePackageRegion,
    fetchPackageRegionById,
    updatePackageRegion,
    deletePackageRegion
} from '../service';

import {
    SET_PACKAGE_REGIONS,
    SET_PACKAGE_REGIONS_DROPDOWN,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_PACKAGE_REGION_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

import {
    changeDataToDropDownFormat
} from '../../../utils';

/**
 * Get list of package regions
 * 
 * @param {*} param0 
 */
export const fetchAndSetPackageRegions = ({ commit },  {query}) => {
    return fetchPackageRegions(query)
        .then((response) => {
            commit(SET_PACKAGE_REGIONS, response.data)
            return Promise.resolve(response);
        });
}

export const createPackageRegion = ({ commit }, data) => {
    return storePackageRegion(data)
        .then((response) => {
            commit;
            // commit(CLEAR_FORM)
            return Promise.resolve(response);
        });
}

export const fetchAndSetPackageRegionById = ({ commit }, packageRegionId) => {
    return fetchPackageRegionById(packageRegionId)
        .then((response) => {
            commit(SET_PACKAGE_REGION_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updatePackageRegionAction = ({ commit }, {packageRegionId, data}) => {
    return updatePackageRegion(packageRegionId, data)
        .then((response) => {
            commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const deletePackageRegionAction = ({ commit }, packageRegionId) => {
    return deletePackageRegion(packageRegionId)
        .then((response) => {
            commit;
            return Promise.resolve(response);
        })
}
export const fetchAndSetPackageRegionsDropdown = ({ commit }) => {
    return fetchPackageRegions()
        .then((response) => {
            commit(SET_PACKAGE_REGIONS_DROPDOWN, changeDataToDropDownFormat(response.data.data))
            return Promise.resolve(response);
        });
}
export const fetchAndSetAllPackageRegionsDropdown = ({ commit }) => {
    return fetchAllPackageRegions()
        .then((response) => {
            commit(SET_PACKAGE_REGIONS_DROPDOWN, changeDataToDropDownFormat(response.data.data))
            return Promise.resolve(response);
        });
}

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const clearForm = ({
    commit
  }, data) => commit(CLEAR_FORM, data);

  export const setSearchParameters = ({
    commit
  }, data) => commit(SET_SEARCH_PARAMETERS, data);
  
  export const clearSearchParameters = ({
      commit
    }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);