export const SET_USERS   = 'SET_USERS';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_USER_FORM = 'SET_USER_FORM';

export const SET_PROFILE_FORM = 'SET_PROFILE_FORM';

export const SET_USER_TYPES_DROPDOWN   = 'SET_USER_TYPES_DROPDOWN';

export const UPDATE_FORM = 'UPDATE_FORM';

export const UPDATE_PROFILE_FORM = 'UPDATE_PROFILE_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
