export const SET_PACKAGE_REGIONS   = 'SET_PACKAGE_REGIONS';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_PACKAGE_REGION_FORM = 'SET_PACKAGE_REGION_FORM';

export const SET_PACKAGE_REGIONS_DROPDOWN   = 'SET_PACKAGE_REGIONS_DROPDOWN';

export const UPDATE_FORM = 'UPDATE_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
