import {
    fetchUsers,
    fetchUserTypes,
    storeUser,
    fetchUserById,
    updateUser,
    deleteUser,
    updateProfile
} from '../service';

import {
    SET_USERS,
    SET_USER_TYPES_DROPDOWN,
    CLEAR_FORM,
    UPDATE_FORM,
    UPDATE_PROFILE_FORM,
    SET_USER_FORM,
    SET_PROFILE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS,
} from '../mutation-types';

import {
    changeDataToDropDownFormat
} from '../../../utils';

/**
 * Get list of package regions
 * 
 * @param {*} param0 
 */
export const fetchAndSetUsers = ({ commit }, {query}) => {
    return fetchUsers(query)
        .then((response) => {
            commit(SET_USERS, response.data)
            return Promise.resolve(response);
        });
}

export const createUser = ({ commit }, data) => {
    return storeUser(data)
        .then((response) => {
            commit;
            // commit(CLEAR_FORM)
            return Promise.resolve(response);
        });
}

export const fetchAndSetUserById = ({ commit }, userId) => {
    return fetchUserById(userId)
        .then((response) => {
            commit(SET_USER_FORM, response.data.data);
            return Promise.resolve(response);
        })
}
export const fetchAndSetProfileById = ({ commit }, userId) => {
    return fetchUserById(userId)
        .then((response) => {
            commit(SET_PROFILE_FORM, response.data.data.profile);
            return Promise.resolve(response);
        })
}

export const updateUserAction = ({ commit }, {userId, data}) => {
    return updateUser(userId, data)
        .then((response) => {
            commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const updateProfileAction = ({ commit }, {userId, data}) => {
    return updateProfile(userId, data)
        .then((response) => {
            // commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const deleteUserAction = ({ commit }, userId) => {
    return deleteUser(userId)
        .then((response) => {
            commit;
            return Promise.resolve(response);
        })
}
export const fetchAndSetUserTypesDropdown = ({ commit }) => {
    return fetchUserTypes()
        .then((response) => {
            commit(SET_USER_TYPES_DROPDOWN, changeDataToDropDownFormat(response.data.data))
            return Promise.resolve(response);
        });
}

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const updateProfileForm = ({
  commit
}, data) => commit(UPDATE_PROFILE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);