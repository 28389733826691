/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: HopNepal
  Author URL: https://www.hopnepal.com
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);


// axios
import axios from "axios";
import { isUserLoggedIn, getAccessToken } from './services/auth';
// axios.defaults.baseURL = process.env.API_URL;
 axios.defaults.baseURL = "https://api.hopnepal.com";
// axios.defaults.baseURL = "http://booking-manager-api.test";
// console.log(process.env.API_URL)
if (isUserLoggedIn()) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
}



// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store';

import Interceptors from './setup/interceptors';
Interceptors.init(store);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// CkEditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor)


// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
