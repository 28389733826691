import {
    SET_USER_FORM,
    CLEAR_FORM,
    UPDATE_FORM
} from '../mutation-types';

export default {
    [CLEAR_FORM](state) {
        state.form = {
          id: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          username: "",
        };
        
        state.passwordForm = {
          old_password: "",
          new_password: "",
          confirm_password: "",
        };
    },

    [SET_USER_FORM](state, records) {
        state.form = {
          id: records.id,
          first_name: records.first_name,
          middle_name: records.middle_name,
          last_name: records.last_name,
          email: records.email,
          username: records.username,
        };
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      state.form[key] = value;
      state.passwordForm[key] = value;
    },
};