
/**
 * Get list of amenities
 * 
 * @param {object} state 
 */
export const getTopDeals = (state) => {
    return state.topdeals;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getDropdownObj = (state) => {
    return state.dropdown;
}

export const getTopDealTypes = (state) => {
    return state.types;
}


export const getImages = (state) => {
    return state.topdealImages;
}