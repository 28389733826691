export default {
    features: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            title: '',
        },
    },
    form: {
        title: "",
        status: true,
    },
}