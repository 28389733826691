/**
 * Get list of Pages
 *
 * @param {object} state
 */
export const getPages = (state) => {
    return state.pages;
}

export const getPageImages = (state) => {
    return state.pageImages;
}

export const getPageAlt = (state) => {
  return state.form.alt;
}

export const  getFormObj = (state) => {
    return state.form;
}
export const  getFormDropdownObj = (state) => {
    return state.form_dropdown;
}

export const  getParentPagesDropdownObj = (state) => {
  return state.parent_pages;
}

export const previewImage = (state) => {
    return state.image_preview;
}

export const getSearchObj = (state) => {
    return state.search;
}
