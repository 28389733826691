import template from 'lodash/fp/template';
import {
    FETCH_HOMESTAYS,
    STORE_HOMESTAY,
    FETCH_HOMESTAY_DETAILS,
    UPDATE_HOMESTAY,
    DELETE_HOMESTAY,
    SET_AMENITIES,
    FETCH_USERS,
    FETCH_COUNTRIES,
    DELETE_HOMESTAY_IMAGE,
} from './api';

import axios from 'axios';

const config = { headers: { "Content-Type": "multipart/form-data" } };

/**
 * Fetch HomeStays
 */
export const fetchHomeStays = (query) => axios.get(template(FETCH_HOMESTAYS)({ query }));

/**
 * Store package
 */
export const storeHomeStay = (data) => axios.post(STORE_HOMESTAY, data);

/**
 * Fetch HomeStay by id.
 * 
 * @param {Integer} id 
 */
export const fetchHomeStayById = (id) => axios.get(template(FETCH_HOMESTAY_DETAILS)({ id }));

/**
 * Update HomeStay region.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHomeStay = (id, data) => axios.patch(template(UPDATE_HOMESTAY)({ id }), data);

/**
 * Delete HomeStay Data
 * 
 * @param {Integer} id 
 */
export const deleteHomeStay = (id) => axios.delete(template(DELETE_HOMESTAY)({ id }));

/**
 * Delete HomeStay Image
 * 
 * @param {Integer} id 
 */

export const deleteHomeStayImage = (id) => axios.delete(template(DELETE_HOMESTAY_IMAGE)({ id }));

/**
 * Fetch Amenities
 */
export const fetchHomestayAmenities = () => axios.get(SET_AMENITIES);

/**
 * Fetch Geo Countries
 */
export const fetchCountries = () => axios.get(FETCH_COUNTRIES);

/**
 * Fetch List of vendors.
 */
export const fetchUsers = () => axios.get(FETCH_USERS);