export default {
    seo_pages: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: '',
            display_name: '',
            type: '',
        },
    },
    form: {
        title: "",
        description: "",
        title_footer: "",
        description_footer: "",
        details_duration: "",
        details_cost: "",
        details_fav_time: "",
        details_permit: "",
        details_transport: "",
        itinerary: [
            {
                title: '',
                description: ''
            }
        ],
        cost_description: {
            title: '',
            description: '',
            on_budget: '',
            mid_range_budget: '',
            high_end_budget: '',
            permit_cost: '',
        },
        hero_text: "",
        seopageable_id: "",
        seopageable_type: {},
        banner_image: {
            file: '',
            alt: '',
            img_url: ''
        },
        hero_image: {
            file: '',
            alt: '',
            img_url: ''
        },
        gallery: [
            {
                file: '',
                alt: '',
                img_url: ''
            }
        ]
    },

    seopageable_type_dropdown: [],
    dropdown:{
        seopageable_id:{},
        seopageable_type:{}
    },
    heroImages:[],
    galleryImages:[],
    bannerImages: []
}