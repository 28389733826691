import {
  fetchUser,
  updateUser,
  updateUserPassword,
} from '../service';

import {
  SET_USER_FORM,
  CLEAR_FORM,
  UPDATE_FORM,
} from '../mutation-types';

/**
 * Get list of Amenitys
 * 
 * @param {*} param0 
 */
export const fetchAndSetUser = ({
  commit
}) => {
  return fetchUser()
    .then((response) => {
      commit(SET_USER_FORM, response.data)
      return Promise.resolve(response);
    });
}

export const updateUserAction = ({
  commit
}, data) => {
  return updateUser(data)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const updateUserPasswordAction = ({
  commit
}, data) => {
  return updateUserPassword(data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);