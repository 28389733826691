import {
    fetchPackageActivities,
    storePackageActivity,
    fetchPackageActivityById,
    updatePackageActivity,
    deletePackageActivity
} from '../service';

import {
    SET_PACKAGE_ACTIVITIES,
    SET_PACKAGE_ACTIVITY_DROPDOWN,
    CLEAR_FORM,
    SET_PACKAGE_ACTIVITY_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

import {
    changeDataToDropDownFormat
} from '../../../utils';

/**
 * Get list of package activities
 * 
 * @param {*} param0 
 */
export const fetchAndSetPackageActivities = ({ commit },  {query}) => {
    return fetchPackageActivities(query)
        .then((response) => {
            commit(SET_PACKAGE_ACTIVITIES, response.data)
            return Promise.resolve(response);
        });
}

export const createPackageActivity = ({ commit }, data) => {
    return storePackageActivity(data)
        .then((response) => {
            return Promise.resolve(response);
        });
}

export const fetchAndSetPackageActivityById = ({ commit }, packageActivityId) => {
    return fetchPackageActivityById(packageActivityId)
        .then((response) => {
            commit(SET_PACKAGE_ACTIVITY_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updatePackageActivityAction = ({ commit }, {packageActivityId, data}) => {
    return updatePackageActivity(packageActivityId, data)
        .then((response) => {
            return Promise.resolve(response);
        })
}

export const deletePackageActivityAction = ({ commit }, packageActivityId) => {
    return deletePackageActivity(packageActivityId)
        .then((response) => {
            return Promise.resolve(response);
        })
}

export const clearForm = ({ commit }) => commit(CLEAR_FORM);

export const updateForm = ({
    commit
  }, data) => commit(UPDATE_FORM, data);

  export const setSearchParameters = ({
    commit
  }, data) => commit(SET_SEARCH_PARAMETERS, data);
  
  export const clearSearchParameters = ({
      commit
    }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);