import {
    SET_NOTICES,
    SET_NOTICE_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_NOTICES](state, records ) {
        state.notices.data = records.data;
        state.notices.pagination.last_page = records.meta.last_page;
        state.notices.pagination.per_page = records.meta.per_page;
        state.notices.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
            title: "",
            description: "",
            type: "",
            status: true,
        };

        state.dropdown.type=[]
    },

    [SET_NOTICE_FORM](state, records) {
        state.form = {
            title: records.title,
            description: records.description,
            type: records.type,
            status: records.status,
        };

        state.dropdown = {
            type:  {
                label: records.type,
                value: records.type,
            },
        }
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
        if (state.dropdown[key]) {
            state.dropdown[key] = value;
            state.form[key] = value.value;
            return;
        }

        state.form[key] = value;
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.title = '';
        state.search.query.type = '';
    },
};