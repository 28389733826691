export const SET_NOTICES   = 'SET_NOTICES';

export const SET_NOTICE_FORM   = 'SET_NOTICE_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';