
/**
 * Get list of package regions
 * 
 * @param {object} state 
 */
export const getPackageActivities = (state) => state.package_activities;

/**
 * Get form object.
 * 
 * @param {Object} state 
 */
export const getFormObj = (state) => state.form;

/**
 * Image Preview
 * 
 * @param {Object} state 
 */
export const getImagePreview = (state) => state.image.preview;

export const getImage = (state) => state.image;
export const getImages = (state) => {
    return state.images;
}

export const getSearchObj = (state) => {
    return state.search;
}