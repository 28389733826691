
/**
 * Get list of categories
 * 
 * @param {object} state 
 */
export const getCategories = (state) => {
    return state.categories;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}