export default {
    categories: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: '',
            display_name: ''
        },
    },
    form: {
        name: "",
        slug: "",
        display_name: "",
        status: false,
    },
}