export default {
    amenities: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: '',
            display_name: '',
            type: '',
        },
    },
    form: {
        name: "",
        display_name: "",
        type: "",
        status: true,
    },
    types: [
        {label: 'Hotel', value: 'hotel'},
        {label: 'Homestay', value: 'homestay'},
        {label: 'Room', value: 'room'}
    ],
    dropdown:{
        type:{}
    },
    
    amenityImages:[]
}