export default {
    users: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 50,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            email: '',
            full_name: '',
            role: '',
        },
    },
    user_types: [],
    form_dropdown: {
        role_id: {}
    },
    form: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
        role_id: "",
        status: false,
    },
    profile_form: {
        address_1: "",
        address_2: "",
        citizenship_number: "",
        company_name: "",
        employees: "",
        established_at: "",
        media: "",
        pan_number: "",
        phone_1: "",
        phone_2: "",
        tan_number: "",
        vat_number: "",
        image: [],
        pan: [],
        vat: [],
        tan: [],
        image_url: '',
        pan_image_url: '',
        vat_image_url: '',
        tan_image_url: '',
    },
    
    profileImages:[],
    imageAlts:[]
}