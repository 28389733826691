
/**
 * Get list of blogs
 * 
 * @param {object} state 
 */
export const getblogs = (state) => {
    return state.blogs;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getBlogCategories = (state) => {
    return state.blogCategories;
}

export const getPackages = (state) => {
    return state.packages;
}

export const getHomestays = (state) => {
    return state.homestays;
}

export const getHotels = (state) => {
    return state.hotels;
}
export const getDropdownObj = (state) => {
    return state.dropdown;
}


export const getImages = (state) => {
    return state.blogImages;
}

export const getImageAlt = (state) => {
    return state.alt;
}