
export default [
    {
        path: '/roles',
        name: 'roles',
        component: () => import('./page/List.vue')
    },
    {
      path: '/roles/create',
      name: 'roles.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/roles/:roleId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'roles:edit',
    },
]