import {changeDataToDropDownFormat} from '../../../utils'
import {
  fetchBlogs,
  storeBlog,
  fetchBlogById,
  updateBlog,
  deleteBlog,
  fetchCategories,
  fetchPackages,
  fetchHomestays,
  fetchHotels,
} from '../service';

import {
  SET_BLOGS,
  SET_CATEGORIES,
  SET_PACKAGES,
  SET_HOMESTAYS,
  SET_HOTELS,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_BLOG_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Blogs
 *
 * @param {*} param0
 */
export const fetchAndSetBlogs = ({
  commit
},  {query}) => {
  return fetchBlogs(query)
    .then((response) => {
      commit(SET_BLOGS, response.data)
      return Promise.resolve(response);
    });
}
/**
 * Get list of Packages
 *
 * @param {*} param0
 */
export const fetchAndSetPackages = ({
  commit
}) => {
  return fetchPackages()
    .then((response) => {
      commit(SET_PACKAGES, response.data)
      return Promise.resolve(response);
    });
}

/**
 * Get list of Homestays
 *
 * @param {*} param0
 */
export const fetchAndSetHomestays = ({
  commit
}) => {
  return fetchHomestays()
    .then((response) => {
      commit(SET_HOMESTAYS, response.data)
      return Promise.resolve(response);
    });
}

/**
 * Get list of Hotels
 *
 * @param {*} param0
 */
export const fetchAndSetHotels = ({
  commit
}) => {
  return fetchHotels()
    .then((response) => {
      commit(SET_HOTELS, response.data)
      return Promise.resolve(response);
    });
}

/**
 * Get list of Categories
 *
 * @param {*} param0
 */
export const fetchAndSetCategories = ({
  commit
}) => {
  return fetchCategories()
    .then((response) => {
      commit(SET_CATEGORIES, changeDataToDropDownFormat(response.data.data, 'display_name'))
      return Promise.resolve(response);
    });
}

export const createBlog = ({
  commit
}, data) => {
  return storeBlog(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetBlogById = ({
  commit
}, blogId) => {
  return fetchBlogById(blogId)
    .then((response) => {
      commit(SET_BLOG_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateBlogAction = ({
  commit
}, {
  blogId,
  data
}) => {
  return updateBlog(blogId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteBlogAction = ({
// eslint-disable-next-line no-unused-vars
  commit
}, blogId) => {
  return deleteBlog(blogId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);
