/**
 * Get list of features
 * 
 * @param {object} state 
 */
export const getFeatures = (state) => {
    return state.features;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

