import template from 'lodash/fp/template';
import axios from 'axios';

import {
    FETCH_VENDORS,
    FETCH_DEPARTURE_TYPES,
    FETCH_PACKAGE_TYPES,
    FETCH_LISTING_TYPES,
    FETCH_COUNTRIES,
    FETCH_STATES,
    FETCH_CITIES,
    FETCH_USERS,
    FETCH_AMENITIES,
    FETCH_FEATURES,
    FETCH_NOTICES,
    FETCH_ROOM_TYPES,
    FETCH_PROPERTY_TYPES
} from '../api';

/**
 * Fetch Countries
 */
export const fetchUsers = () => axios.get(FETCH_USERS);

/**
 * Fetch Countries
 */
export const fetchCountries = () => axios.get(FETCH_COUNTRIES);


/**
 * Fetch States
 */
export const fetchStates = (countryId) => axios.get(template(FETCH_STATES)({ countryId }));

/**
 * Fetch States
 */
export const fetchCities = (stateId) => axios.get(template(FETCH_CITIES)({ stateId }));

/**
 * Fetch vendors
 */
export const fetchVendors = () => axios.get(FETCH_VENDORS);

/**
 * Fetch departure types
 */
export const fetchDepartureTypes = () => axios.get(FETCH_DEPARTURE_TYPES);

/**
 * Fetch package types
 */
export const fetchPackageTypes = () => axios.get(FETCH_PACKAGE_TYPES);

/**
 * Fetch listing types
 */
export const fetchListingTypes = () => axios.get(FETCH_LISTING_TYPES);

/**
* Fetch Amenities
*/
export const fetchAmenities = (query) => axios.get(template(FETCH_AMENITIES)({ query }));

/**
* Fetch Amenities
*/
export const fetchFeatures = () => axios.get(FETCH_FEATURES);

/**
* Fetch Amenities
*/
export const fetchNotices = () => axios.get(FETCH_NOTICES);

/**
 * Fetch room types
 */
export const fetchRoomTypes = () => axios.get(FETCH_ROOM_TYPES);

/**
 * Fetch property types
 */
export const fetchPropertyTypes = () => axios.get(FETCH_PROPERTY_TYPES);
