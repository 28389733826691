import template from 'lodash/fp/template';
import {
  FETCH_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD
} from './api';

import axios from 'axios';

/**
 * Fetch User
 */
export const fetchUser = () => axios.get(FETCH_USER);

/**
 * Update User.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateUser = (data) => axios.post(UPDATE_USER, data);

/**
 * Update User Password.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateUserPassword = (data) => axios.post(UPDATE_USER_PASSWORD, data);
