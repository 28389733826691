import template from 'lodash/fp/template';
import {
  FETCH_TOP_DEALS,
  STORE_TOP_DEAL,
  FETCH_TOP_DEAL_DETAILS,
  UPDATE_TOP_DEAL,
  DELETE_TOP_DEAL
} from './api';

import axios from 'axios';

/**
 * Fetch TopDeals
 */
export const fetchTopDeals = (query) => axios.get(template(FETCH_TOP_DEALS)({ query }));

/**
 * Store TopDeal
 */
export const storeTopDeal = (data) => axios.post(STORE_TOP_DEAL, data);

/**
 * Fetch TopDeal by id.
 * 
 * @param {Integer} id 
 */
export const fetchTopDealById = (id) => axios.get(template(FETCH_TOP_DEAL_DETAILS)({
  id
}));

/**
 * Update TopDeal.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateTopDeal = (id, data) => axios.patch(template(UPDATE_TOP_DEAL)({
  id
}), data);

/**
 * Delete TopDeal.
 * 
 * @param {*} id 
 */
export const deleteTopDeal = (id) => axios.delete(template(DELETE_TOP_DEAL)({
  id
}))
