import {
    SET_LISTING_TYPES_DROPDOWN
} from './mutation-type';

import {fetchListingTypes} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    listing_types_dropdown: [],
};

const getters = {
    fetchListingTypesDropdown : (state) => state.listing_types_dropdown,
};

const actions = {
    fetchAndSetListingTypesDropdown({ commit }) {
        fetchListingTypes().then((response) => {
            commit(SET_LISTING_TYPES_DROPDOWN, changeDataToDropDownFormat(response.data.data));
        });
    }
};

const mutations = {
    [SET_LISTING_TYPES_DROPDOWN](state, records ) {
        state.listing_types_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
