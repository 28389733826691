
export default [
    {
        path: '/package-regions',
        name: 'package_regions',
        component: () => import('./page/List.vue')
    },
    {
      path: '/package-regions/create',
      name: 'package_regions.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/package-regions/:packageRegionId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'package_regions:edit',
  }
]