export const SET_TOP_DEALS   = 'SET_TOP_DEALS';

export const SET_TOP_DEAL_FORM   = 'SET_TOP_DEAL_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';