import template from 'lodash/fp/template';
import {
  FETCH_AMENITIES,
  STORE_AMENITY,
  FETCH_AMENITY_DETAILS,
  UPDATE_AMENITY,
  DELETE_AMENITY
} from './api';

import axios from 'axios';

/**
 * Fetch Amenities
 */
export const fetchAmenities = (query) => axios.get(template(FETCH_AMENITIES)({ query }));

/**
 * Store Amenity
 */
export const storeAmenity = (data) => axios.post(STORE_AMENITY, data);

/**
 * Fetch Amenity by id.
 * 
 * @param {Integer} id 
 */
export const fetchAmenityById = (id) => axios.get(template(FETCH_AMENITY_DETAILS)({
  id
}));

/**
 * Update Amenity.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateAmenity = (id, data) => axios.patch(template(UPDATE_AMENITY)({
  id
}), data);

/**
 * Delete Amenity.
 * 
 * @param {*} id 
 */
export const deleteAmenity = (id) => axios.delete(template(DELETE_AMENITY)({
  id
}))
