import {
    SET_COUNTRIES_DROPDOWN,
    SET_STATES_DROPDOWN,
    SET_CITIES_DROPDOWN,
} from './mutation-type';

import {fetchCountries, fetchStates, fetchCities} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    countries_dropdown: [],
    states_dropdown: [],
    cities_dropdown: [],
};

const getters = {
    fetchCountriesDropdown : (state) => state.countries_dropdown,
    fetchStatesDropdown : (state) => state.states_dropdown,
    fetchCitiesDropdown : (state) => state.cities_dropdown,
};

const actions = {
    fetchAndSetCountriesDropdown({ commit }) {
        fetchCountries().then((response) => {
            commit(SET_COUNTRIES_DROPDOWN, changeDataToDropDownFormat(response.data, 'name'));
        });
    },
    fetchAndSetStatesDropdown({ commit }, countryId) {
        fetchStates(countryId).then((response) => {
            commit(SET_STATES_DROPDOWN, changeDataToDropDownFormat(response.data, 'name'));
        });
    },
    fetchAndSetCitiesDropdown({ commit }, stateId) {
        fetchCities(stateId).then((response) => {
            commit(SET_CITIES_DROPDOWN, changeDataToDropDownFormat(response.data, 'name'));
        });
    }

};

const mutations = {
    [SET_COUNTRIES_DROPDOWN](state, records ) {
        state.countries_dropdown = records;
    },
    [SET_STATES_DROPDOWN](state, records ) {
        state.states_dropdown = records;
    },
    [SET_CITIES_DROPDOWN](state, records ) {
        state.cities_dropdown = records;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
