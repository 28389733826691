
export const SEO_PAGEABLE_TYPE = [
   {
    label: 'Package Region',
    value: 'App\\Models\\PackageRegion'
   },
   {
    label: 'Hotel Region',
    value: 'App\\Models\\HotelRegion'
   },
   {
    label: 'Homestay Region',
    value: 'App\\Models\\HomestayRegion'
   }
]