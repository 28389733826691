import {
    SET_FEATURES_DROPDOWN
} from './mutation-type';

import {fetchFeatures} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    features_dropdown: [],
};

const getters = {
    fetchFeaturesDropdown : (state) => state.features_dropdown,
};

const actions = {
    fetchAndSetFeaturesDropdown({ commit }) {
        fetchFeatures().then((response) => {
            commit(SET_FEATURES_DROPDOWN, changeDataToDropDownFormat(response.data.data, 'title'));
        });
    }
};

const mutations = {
    [SET_FEATURES_DROPDOWN](state, records ) {
        state.features_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
