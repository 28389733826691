import {
  fetchCategories,
  storeCategory,
  fetchCategoryById,
  updateCategory,
  deleteCategory
} from '../service';

import {
  SET_BLOG_CATEGORIES,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_BLOG_CATEGORY_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Categories
 * 
 * @param {*} param0 
 */
export const fetchAndSetCategories = ({
  commit
},  {query}) => {
  return fetchCategories(query)
    .then((response) => {
      commit(SET_BLOG_CATEGORIES, response.data)
      return Promise.resolve(response);
    });
}

export const createCategory = ({
  commit
}, data) => {
  return storeCategory(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetCategoryById = ({
  commit
}, categoryId) => {
  return fetchCategoryById(categoryId)
    .then((response) => {
      commit(SET_BLOG_CATEGORY_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateCategoryAction = ({
  commit
}, {
  categoryId,
  data
}) => {
  return updateCategory(categoryId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteCategoryAction = ({
  commit
}, categoryId) => {
  return deleteCategory(categoryId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);