import {
  fetchSeoPages,
  storeSeoPage,
  fetchSeoPageById,
  updateSeoPage,
  deleteSeoPage
} from '../service';

import {
  SET_SEO_PAGES,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  UPDATE_SEOPAGEABLE_ID_DROPDOWN,
  SET_SEO_PAGE_FORM
} from '../mutation-types';
import { fetchAllHotelRegions } from '../../hotel-region/service';
import { changeDataToDropDownFormat } from '../../../utils';
import { fetchAllHomestayRegions } from '../../homestay-region/service';
import { fetchAllPackageRegions } from '../../package-region/service';

/**
 * Get list of Amenitys
 * 
 * @param {*} param0 
 */
export const fetchAndSetSeoPages = ({
  commit
},  {query}) => {
  return fetchSeoPages(query)
    .then((response) => {
      commit(SET_SEO_PAGES, response.data)
      return Promise.resolve(response);
    });
}

export const createSeoPage = ({
  commit
}, data) => {
  return storeSeoPage(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetSeoPageById = ({
  commit
}, seoPageId) => {
  return fetchSeoPageById(seoPageId)
    .then(async (response) => {
      const dropdownData = await transformDropDownData(response.data.data.seopageable_type)
      commit(UPDATE_SEOPAGEABLE_ID_DROPDOWN, dropdownData)
      const seopageable = dropdownData.find(dropdown => dropdown.value === response.data.data.seopageable_id);
      
      commit(SET_SEO_PAGE_FORM, {
        records: response.data.data,
        seopageable
      });
      return Promise.resolve(response);
    })
}

export const updateSeoPageAction = ({
  commit
}, {
  seoPageId,
  data
}) => {
  return updateSeoPage(seoPageId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteSeoPageAction = ({
  commit
}, seoPageId) => {
  return deleteSeoPage(seoPageId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = async ({
  commit
}, data) => {
  if(data.key === 'seopageable_type') {
    const dropdownData = await transformDropDownData(data.value.value)

    commit(UPDATE_SEOPAGEABLE_ID_DROPDOWN, dropdownData)
    commit(UPDATE_FORM, {key: 'seopageable_id', value: dropdownData[0]});
  }

  commit(UPDATE_FORM, data);
}

const transformDropDownData = async (seopageableType) => {
  let dropdownData = [];

  if(seopageableType === 'App\\Models\\HotelRegion') {
    const hotels = await fetchAllHotelRegions();
    dropdownData = changeDataToDropDownFormat(hotels.data.data);
  } else if(seopageableType === 'App\\Models\\HomestayRegion') {
    const homestays = await fetchAllHomestayRegions();
    dropdownData = changeDataToDropDownFormat(homestays.data.data);
  } else {
    const packages = await fetchAllPackageRegions();
    dropdownData = changeDataToDropDownFormat(packages.data.data);
  }

  return dropdownData;
}

export const updateImageForm = async ({
  commit,
  state
}, data) => {
  const image = JSON.parse(JSON.stringify(state.form[data.field]))
  const value = {...image, [data.key]: data.value };

  commit(UPDATE_FORM, { key: data.field, value: value});
}

export const updateCostForm = async ({
  commit,
  state
}, data) => {
  const cost_description = JSON.parse(JSON.stringify(state.form.cost_description))
  cost_description[data.key] = data.value;

  commit(UPDATE_FORM, { key: 'cost_description', value: cost_description});
}

export const addMoreItinerary = async ({
  commit,
  state
}) => {
  commit(UPDATE_FORM, {key: 'itinerary', value: [...state.form.itinerary, 
     { title: '',  description: ''}
  ]});
}

export const updateItineraryForm = async ({
  commit,
  state
}, data) => {
  const itineraries = JSON.parse(JSON.stringify(state.form.itinerary))
  itineraries[data.index][data.key] = data.value;
  commit(UPDATE_FORM, {key: 'itinerary', value: itineraries});
}

export const addMoreGallery = async ({
  commit,
  state
}) => {
  commit(UPDATE_FORM, {key: 'gallery', value: [...state.form.gallery, 
     { alt: '',  file: ''}
  ]});
}

export const updateGalleryForm = async ({
  commit,
  state
}, data) => {
  const galleries = state.form.gallery;
  galleries[data.index][data.key] = data.value;
  commit(UPDATE_FORM, {key: 'gallery', value: galleries});
}

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);