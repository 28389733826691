export default {
    package_regions: {
        data: [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: '',
            description: ''
        },
    },
    package_regions_dropdown: [],
    form: {
        name: "",
        slug: "",
        alt_image: "",
        description: "",
        display_home: true,
        status: true,
        images: null,
        meta_page_title: '',
        meta_keywords: '',
        meta_description: '',
    },
    image_preview: null,
    images: [],
}