
/**
 * Get list of users
 * 
 * @param {object} state 
 */
export const getUsers = (state) => {
    return state.users;
}


export const getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getProfileFormObj = (state) => {
    return state.profile_form;
}

export const getFormDropdownObj = (state) => {
    return state.form_dropdown;
}

export const fetchUserTypesDropdown = (state) => {
    return state.user_types;
}

export const getProfileImages = (state) => {
    return state.profileImages;
}

export const getImageAlts = (state) => {
    return state.imageAlts;
}