import {
  fetchNotices,
  storeNotice,
  fetchNoticeById,
  updateNotice,
  deleteNotice
} from '../service';

import {
  SET_NOTICES,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_NOTICE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Notices
 * 
 * @param {*} param0 
 */
export const fetchAndSetNotices = ({
  commit
},  {query}) => {
  return fetchNotices(query)
    .then((response) => {
      commit(SET_NOTICES, response.data)
      return Promise.resolve(response);
    });
}

export const createNotice = ({
  commit
}, data) => {
  return storeNotice(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetNoticeById = ({
  commit
}, noticeId) => {
  return fetchNoticeById(noticeId)
    .then((response) => {
      commit(SET_NOTICE_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateNoticeAction = ({
  commit
}, {
  noticeId,
  data
}) => {
  return updateNotice(noticeId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteNoticeAction = ({
  commit
}, noticeId) => {
  return deleteNotice(noticeId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);