import template from 'lodash/fp/template';
import {
  FETCH_ROLES,
  FETCH_PERMISSIONS,
  STORE_ROLE,
  FETCH_ROLE_DETAILS,
  UPDATE_ROLE,
  DELETE_ROLE
} from './api';

import axios from 'axios';

/**
 * Fetch Roles
 */
export const fetchRoles = (query) => axios.get(template(FETCH_ROLES)({ query }));

/**
 * Fetch Permissions
 */
export const fetchPermissions = () => axios.get(FETCH_PERMISSIONS);

/**
 * Store Role
 */
export const storeRole = (data) => axios.post(STORE_ROLE, data);

/**
 * Fetch Role by id.
 * 
 * @param {Integer} id 
 */
export const fetchRoleById = (id) => axios.get(template(FETCH_ROLE_DETAILS)({
  id
}));

/**
 * Update Role.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateRole = (id, data) => axios.patch(template(UPDATE_ROLE)({
  id
}), data);

/**
 * Delete Role.
 * 
 * @param {*} id 
 */
export const deleteRole = (id) => axios.delete(template(DELETE_ROLE)({
  id
}))
