import {
  fetchRoles,
  fetchPermissions,
  storeRole,
  fetchRoleById,
  updateRole,
  deleteRole
} from '../service';

import {
  SET_ROLES,
  SET_PERMISSIONS,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_ROLE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Roles
 * 
 * @param {*} param0 
 */
export const fetchAndSetRoles = ({
  commit
},  {query}) => {
  return fetchRoles(query)
    .then((response) => {
      commit(SET_ROLES, response.data)
      return Promise.resolve(response);
    });
}

/**
 * Get list of Permissions
 * 
 * @param {*} param0 
 */
export const getAndSetPermissions = ({
  commit
}) => {
  return fetchPermissions()
    .then((response) => {
      commit(SET_PERMISSIONS, response.data)
      return Promise.resolve(response);
    });
}

export const createRole = ({
  commit
}, data) => {
  return storeRole(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetRoleById = ({
  commit
}, roleId) => {
  return fetchRoleById(roleId)
    .then((response) => {
      commit(SET_ROLE_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateRoleAction = ({
  commit
}, {
  roleId,
  data
}) => {
  return updateRole(roleId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteRoleAction = ({
  commit
}, roleId) => {
  return deleteRole(roleId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);