
/**
 * Get list of package regions
 * 
 * @param {object} state 
 */
export const getPackageRegions = (state) => state.package_regions;

export const getFormObj = (state) => state.form;

export const getPackageRegionsDropdown = (state) => state.package_regions_dropdown;

export const getImagePreview = (state) => state.image_preview;

export const getImages = (state) => {
    return state.images;
}

export const getSearchObj = (state) => {
    return state.search;
}