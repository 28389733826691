import {
    fetchHotelRegions,
    fetchAllHotelRegions,
    storeHotelRegion,
    fetchHotelRegionById,
    updateHotelRegion,
    deleteHotelRegion
} from '../service';

import {
    SET_HOTEL_REGIONS,
    SET_HOTEL_REGIONS_DROPDOWN,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_HOTEL_REGION_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

import {
    changeDataToDropDownFormat
} from '../../../utils';

/**
 * Get list of package regions
 * 
 * @param {*} param
 */
export const fetchAndSetHotelRegions = ({ commit }, { query }) => {
    return fetchHotelRegions(query)
        .then((response) => {
            commit(SET_HOTEL_REGIONS, response.data)
            return Promise.resolve(response);
        });
}

export const createHotelRegion = ({ commit }, data) => {
    return storeHotelRegion(data)
        .then((response) => {
            commit;
            // commit(CLEAR_FORM)
            return Promise.resolve(response);
        });
}

export const fetchAndSetHotelRegionById = ({ commit }, hotelRegionId) => {
    return fetchHotelRegionById(hotelRegionId)
        .then((response) => {
            commit(SET_HOTEL_REGION_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updateHotelRegionAction = ({ commit }, { hotelRegionId, data }) => {
    return updateHotelRegion(hotelRegionId, data)
        .then((response) => {
            commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const deleteHotelRegionAction = ({ commit }, hotelRegionId) => {
    return deleteHotelRegion(hotelRegionId)
        .then((response) => {
            commit;
            return Promise.resolve(response);
        })
}
export const fetchAndSetHotelRegionsDropdown = ({ commit }) => {
    return fetchAllHotelRegions()
        .then((response) => {
            commit(SET_HOTEL_REGIONS_DROPDOWN, changeDataToDropDownFormat(response.data.data))
            return Promise.resolve(response);
        });
}

export const updateForm = ({
    commit
}, data) => commit(UPDATE_FORM, data);

export const clearForm = ({
    commit
}, data) => commit(CLEAR_FORM, data);

export const setSearchParameters = ({
    commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
}, data) => commit(CLEAR_SEARCH_PARAMETERS, data);