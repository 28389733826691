import template from 'lodash/fp/template';
import {
    FETCH_PACKAGE_ACTIVITIES,
    STORE_PACKAGE_ACTIVITY,
    FETCH_PACKAGE_ACTIVITY_DETAILS,
    UPDATE_PACKAGE_ACTIVITY,
    DELETE_PACKAGE_ACTIVITY
} from './api';

import axios from 'axios';

/**
 * Fetch package regions
 */
export const fetchPackageActivities = (query) => axios.get(template(FETCH_PACKAGE_ACTIVITIES)({ query }));

/**
 * Store package activities
 */
export const storePackageActivity = (data) => axios.post(STORE_PACKAGE_ACTIVITY, data);

/**
 * Fetch package activity by id.
 * 
 * @param {Integer} id 
 */
export const fetchPackageActivityById = (id) => axios.get(template(FETCH_PACKAGE_ACTIVITY_DETAILS)({ id }));

/**
 * Update package activity.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updatePackageActivity = (id, data) => axios.patch(template(UPDATE_PACKAGE_ACTIVITY)({ id }), data);


export const deletePackageActivity = (id) => axios.delete(template(DELETE_PACKAGE_ACTIVITY)({ id }))
