import template from 'lodash/fp/template';
import {
  FETCH_FEATURES,
  STORE_FEATURE,
  FETCH_FEATURE_DETAILS,
  UPDATE_FEATURE,
  DELETE_FEATURE
} from './api';

import axios from 'axios';

/**
 * Fetch Features
 */
export const fetchFeatures = (query) => axios.get(template(FETCH_FEATURES)({ query }));

/**
 * Store Feature
 */
export const storeFeature = (data) => axios.post(STORE_FEATURE, data);

/**
 * Fetch Feature by id.
 * 
 * @param {Integer} id 
 */
export const fetchFeatureById = (id) => axios.get(template(FETCH_FEATURE_DETAILS)({
  id
}));

/**
 * Update Feature.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateFeature = (id, data) => axios.patch(template(UPDATE_FEATURE)({
  id
}), data);

/**
 * Delete Feature.
 * 
 * @param {*} id 
 */
export const deleteFeature = (id) => axios.delete(template(DELETE_FEATURE)({
  id
}))
