export const SET_BLOG_CATEGORIES   = 'SET_BLOG_CATEGORIES';

export const SET_BLOG_CATEGORY_FORM   = 'SET_BLOG_CATEGORY_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';