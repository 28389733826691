export const SET_SEO_PAGES   = 'SET_SEO_PAGES';

export const SET_SEO_PAGE_FORM   = 'SET_SEO_PAGE_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const UPDATE_SEOPAGEABLE_ID_DROPDOWN = 'UPDATE_SEOPAGEABLE_ID_DROPDOWN';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';