import {
  fetchBookings,
  fetchBookingById,
  deleteBooking,
  cancelBooking,
} from "../service";

import {
  SET_BOOKINGS,
  SET_BOOKING,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
} from "../mutation-types";

/**
 * Get list of Bookings
 *
 * @param {*} param0
 */
export const fetchAndSetBookings = ({ commit }, { query }) => {
  return fetchBookings(query).then((response) => {
    commit(SET_BOOKINGS, response.data);
    return Promise.resolve(response);
  });
};

export const setSearchParameters = ({ commit }, data) =>
  commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({ commit }, data) =>
  commit(CLEAR_SEARCH_PARAMETERS, data);

export const fetchAndSetBookingById = ({ commit }, bookingId) => {
  return fetchBookingById(bookingId).then((response) => {
    commit(SET_BOOKING, response.data);
    return Promise.resolve(response);
  });
};

/**
 * Delete Booking Data
 *
 * @param {Integer} id
 */
export const deleteBookingAction = ({ commit }, bookingId) => {
  return deleteBooking(bookingId).then((response) => {
    return Promise.resolve(response);
  });
};

/**
 * Cancel Booking Data
 *
 * @param {Integer} id
 */
export const cancelBookingAction = ({ commit }, { bookingId, data }) => {
  return cancelBooking(bookingId, data).then((response) => {
    return Promise.resolve(response);
  });
};
