export const SET_HOTEL_REGIONS   = 'SET_HOTEL_REGIONS';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_HOTEL_REGION_FORM = 'SET_HOTEL_REGION_FORM';

export const SET_HOTEL_REGIONS_DROPDOWN   = 'SET_HOTEL_REGIONS_DROPDOWN';

export const UPDATE_FORM = 'UPDATE_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
