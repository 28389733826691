
/**
 * Get list of Permissions
 * 
 * @param {object} state 
 */
export const getPermissions = (state) => {
    return state.permissions;
}

/**
 * Get list of Roles
 * 
 * @param {object} state 
 */
export const getRoles = (state) => {
    return state.roles;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}