import {
    SET_TOP_DEALS,
    SET_TOP_DEAL_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_TOP_DEALS](state, records ) {
        state.topdeals.data = records.data;
        state.topdeals.pagination.last_page = records.meta.last_page;
        state.topdeals.pagination.per_page = records.meta.per_page;
        state.topdeals.pagination.current_page = records.meta.current_page;
        state.topdeals.pagination.total_records = records.meta.total;
    },

    [CLEAR_FORM](state) {
        state.form = {
            title: "",
            subtitle: "",
            type: "",
            image: [],
            image_url: '',
        };
        
        state.dropdown.type=[];
    },

    [SET_TOP_DEAL_FORM](state, records) {
        state.form = {
            title: records.title,
            subtitle: records.subtitle,
            type: records.type,
            image: [],
            image_url: (records.image) ? records.image[0].url : '',
        };
        state.topdealImages = [];
        let type = records.type.split('-');
        state.dropdown = {
          type:  {
              label: type[0].charAt(0).toUpperCase() + type[0].slice(1) +' '+type[1],
              value: records.type,
          },
        }
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      if (state.dropdown[key]) {
        state.dropdown[key] = value;
        state.form[key] = value.value;
        return;
      }

      if( key== 'image'){
        if(state.topdealImages[0]){
          state.topdealImages[0] = value;
        }else{
            state.topdealImages.push(value);
        }
        state.form.image.push(value);
      }else{

        state.form[key] = value;
      }
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.title = '';
        state.search.query.subtitle = '';
        state.search.query.type = '';
    },
};