
export default [
    {
        path: '/topdeals',
        name: 'topdeals',
        component: () => import('./page/List.vue')
    },
    {
      path: '/topdeals/create',
      name: 'topdeals.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/topdeals/:topdealId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'topdeals:edit',
    },
]