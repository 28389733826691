export const SET_BANNERS   = 'SET_BANNERS';

export const SET_BANNER_FORM   = 'SET_BANNER_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';