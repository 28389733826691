/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
    Author: HopNepal
    Author URL: https://www.hopnepal.com
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import user from './app/user/routes';
import role from './app/role/routes';
import package_activity from './app/package-activity/routes';
import package_region from './app/package-region/routes';
import hotel_region from './app/hotel-region/routes';
import packages from './app/package/routes';
import homestays from './app/homestay/routes';
import homestay_region from './app/homestay-region/routes';
import hotel from './app/hotel/routes';
import amenities from './app/amenity/routes';
import banners from './app/banner/routes';
import setting from './app/settings/routes';
import topdeals from './app/topdeals/routes';
import blog_category from './app/blog/category/routes';
import blog from './app/blog/routes';
import cms from './app/cms/routes';
import features from './app/feature/routes';
import notices from './app/notice/routes';
import bookings from './app/booking/routes';

import seo_page from './app/seo-page/routes';
import { isUserLoggedIn } from './services/auth';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue')
        },
        ...role,
        ...user,
        ...package_region,
        ...hotel,
        ...packages,
        ...package_activity,
        ...homestay_region,
        ...homestays,
        ...amenities,
        ...banners,
        ...hotel_region,
        ...topdeals,
        ...blog_category,
        ...blog,
        ...cms,
        ...setting,
        ...features,
        ...notices,
        ...seo_page,
        ...bookings,
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

/**
 * Router validation
 */
router.beforeEach((to, from, next) => {
  if (to.name != 'page-login' && !isUserLoggedIn()) next({ name: 'page-login' });

  next()
})

export default router
