import {
    SET_AMENITIES_DROPDOWN
} from './mutation-type';

import {fetchAmenities} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    amenities_dropdown: [],
};

const getters = {
    fetchAmenitiesDropdown : (state) => state.amenities_dropdown,
};

const actions = {
    fetchAndSetAmenitiesDropdown({ commit }, {query}) {
        fetchAmenities(query).then((response) => {
            commit(SET_AMENITIES_DROPDOWN, changeDataToDropDownFormat(response.data.data, 'display_name'));
        });
    }
};

const mutations = {
    [SET_AMENITIES_DROPDOWN](state, records ) {
        state.amenities_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
