import {
    fetchPages,
    fetchParentPages,
    storePage,
    fetchPageById,
    updatePage,
    deletePage,
    deletePageImage,
} from '../service';

import {
  SET_PAGES,
  CLEAR_FORM,
  SET_PAGE_FORM,
  UPDATE_FORM,
  UPDATE_DROPDOWN_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_PARENT_PAGE_DROPDOWN
} from '../mutation-types';
import {changeDataToDropDownFormat} from "@/utils";

/**
 * Get list of package regions
 *
 * @param {*} param0
 */
export const fetchAndSetPages = ({ commit }, {query}) => {
    return fetchPages(query)
        .then((response) => {
            commit(SET_PAGES, response.data)
            return Promise.resolve(response);
        });
}

export const createPage = ({ commit }, data) => {
    return storePage(data)
        .then((response) => {
            if(response["status_code"] === 201){
                commit(CLEAR_FORM)
            }
            return Promise.resolve(response);
        });
}

export const fetchAndSetPageById = ({ commit }, pageId) => {
    return fetchPageById(pageId)
        .then((response) => {
           commit(SET_PAGE_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const fetchAndSetParentPagesDropdown = ({ commit }) => {
  return fetchParentPages()
    .then((response) => {
      commit(SET_PARENT_PAGE_DROPDOWN, changeDataToDropDownFormat(response.data.data));
      return Promise.resolve(response);
    })
}

export const updatePageAction = ({ commit }, {pageId, data}) => {
    return updatePage(pageId, data)
        .then((response) => {
            commit;
            return Promise.resolve(response);
        })
}

export const deletePageAction = ({ commit }, pageId) => {
    return deletePage(pageId)
        .then((response) => {
          commit;
            return Promise.resolve(response);
        })
}

export const deletePageImageAction = ({ commit }, imageId) => {
    return deletePageImage(imageId)
        .then((response) => {
          commit;
            return Promise.resolve(response);
        })
}


export const updateForm = ({commit}, data) => commit(UPDATE_FORM, data);

export const updateDropdownForm = ({commit}, data) => commit(UPDATE_DROPDOWN_FORM, data);

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);
