import template from 'lodash/fp/template';
import {
  FETCH_NOTICES,
  STORE_NOTICE,
  FETCH_NOTICE_DETAILS,
  UPDATE_NOTICE,
  DELETE_NOTICE
} from './api';

import axios from 'axios';

/**
 * Fetch Notices
 */
export const fetchNotices = (query) => axios.get(template(FETCH_NOTICES)({ query }));

/**
 * Store Notice
 */
export const storeNotice = (data) => axios.post(STORE_NOTICE, data);

/**
 * Fetch Notice by id.
 * 
 * @param {Integer} id 
 */
export const fetchNoticeById = (id) => axios.get(template(FETCH_NOTICE_DETAILS)({
  id
}));

/**
 * Update Notice.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateNotice = (id, data) => axios.patch(template(UPDATE_NOTICE)({
  id
}), data);

/**
 * Delete Notice.
 * 
 * @param {*} id 
 */
export const deleteNotice = (id) => axios.delete(template(DELETE_NOTICE)({
  id
}))
