import template from 'lodash/fp/template';
import {
  FETCH_SEO_PAGES,
  STORE_SEO_PAGE,
  FETCH_SEO_PAGE_DETAILS,
  UPDATE_SEO_PAGE,
  DELETE_SEO_PAGE
} from './api';

import axios from 'axios';

/**
 * Fetch Amenities
 */
export const fetchSeoPages = (query) => axios.get(template(FETCH_SEO_PAGES)({ query }));

/**
 * Store Amenity
 */
export const storeSeoPage = (data) => axios.post(STORE_SEO_PAGE, data);

/**
 * Fetch Amenity by id.
 * 
 * @param {Integer} id 
 */
export const fetchSeoPageById = (id) => axios.get(template(FETCH_SEO_PAGE_DETAILS)({
  id
}));

/**
 * Update Amenity.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateSeoPage = (id, data) => axios.post(template(UPDATE_SEO_PAGE)({
  id
}), data);

/**
 * Delete Amenity.
 * 
 * @param {*} id 
 */
export const deleteSeoPage = (id) => axios.delete(template(DELETE_SEO_PAGE)({
  id
}))
