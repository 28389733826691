import template from 'lodash/fp/template';
import {
    FETCH_HOMESTAY_REGIONS,
    FETCH_ALL_HOMESTAY_REGIONS,
    STORE_HOMESTAY_REGION,
    FETCH_HOMESTAY_REGION_DETAILS,
    UPDATE_HOMESTAY_REGION,
    DELETE_HOMESTAY_REGION
} from './api';

import axios from 'axios';

/**
 * Fetch homestay regions
 */
export const fetchHomestayRegions = (query) => axios.get(template(FETCH_HOMESTAY_REGIONS)({ query }));

/**
 * Fetch homestay regions
 */
export const fetchAllHomestayRegions = () => axios.get(FETCH_ALL_HOMESTAY_REGIONS);

/**
 * Store homestay regions
 */
export const storeHomestayRegion = (data) => axios.post(STORE_HOMESTAY_REGION, data);

/**
 * Fetch homestay region by id.
 * 
 * @param {Integer} id 
 */
export const fetchHomestayRegionById = (id) => axios.get(template(FETCH_HOMESTAY_REGION_DETAILS)({ id }));

/**
 * Update homestay region.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHomestayRegion = (id, data) => axios.patch(template(UPDATE_HOMESTAY_REGION)({ id }), data);


export const deleteHomestayRegion = (id) => axios.delete(template(DELETE_HOMESTAY_REGION)({ id }))
