export default {
    bookings: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    booking: {},
    search: {
        query: {
            id: '',
            user: '',
            service_type: '',
            status: '',
            service_name: '',
        },
    }
}