
export default [
  {
    path: '/homestay-regions',
    name: 'homestay_regions',
    component: () => import('./page/List.vue')
  },
  {
    path: '/homestay-regions/create',
    name: 'homestay_regions.create',
    component: () => import('./page/Create.vue')
  },
  {
    path: '/homestay-regions/:homestayRegionId/edit',
    component: () => import('./page/Edit.vue'),
    name: 'homestay_regions:edit',
  }
]