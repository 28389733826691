export const FETCH_AMENITIES = '/v1/admin/amenities/search?${query}';

export const FETCH_USERS = '/v1/admin/vendors';

export const FETCH_COUNTRIES = '/geo/countries';

export const FETCH_STATES = '/geo/children/${countryId}';

export const FETCH_CITIES = '/geo/children/${stateId}';

export const FETCH_VENDORS = '/v1/admin/vendors';

export const FETCH_DEPARTURE_TYPES = '/v1/admin/package/departure-type';

export const FETCH_PACKAGE_TYPES = '/v1/admin/package/package-type';

export const FETCH_LISTING_TYPES = '/v1/admin/package/listing-type';

export const FETCH_FEATURES = '/v1/admin/features';

export const FETCH_NOTICES = '/v1/admin/notices';

export const FETCH_ROOM_TYPES = '/v1/admin/homestays/room-type';

export const FETCH_PROPERTY_TYPES = '/v1/admin/homestays/property-type';
