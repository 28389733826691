import {
  fetchFeatures,
  storeFeature,
  fetchFeatureById,
  updateFeature,
  deleteFeature
} from '../service';

import {
  SET_FEATURES,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_FEATURE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Features
 * 
 * @param {*} param0 
 */
export const fetchAndSetFeatures = ({
  commit
},  {query}) => {
  return fetchFeatures(query)
    .then((response) => {
      commit(SET_FEATURES, response.data)
      return Promise.resolve(response);
    });
}

export const createFeature = ({
  commit
}, data) => {
  return storeFeature(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetFeatureById = ({
  commit
}, featureId) => {
  return fetchFeatureById(featureId)
    .then((response) => {
      commit(SET_FEATURE_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateFeatureAction = ({
  commit
}, {
  featureId,
  data
}) => {
  return updateFeature(featureId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteFeatureAction = ({
  commit
}, featureId) => {
  return deleteFeature(featureId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);