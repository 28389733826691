import template from 'lodash/fp/template';

import axios from 'axios';
import {
  DELETE_PACKAGE_DEPARTURE,
  FETCH_PACKAGE_DEPARTURE_DETAILS,
  FETCH_PACKAGE_DEPARTURES,
  STORE_PACKAGE_DEPARTURE
} from "./api";
import {FETCH_PACKAGE_ITENARY_DETAILS} from "@/app/package/itenaries/api";

/**
 * Store package departures
 */
export const storePackageDeparture = (packageId, data) => axios.post(template(STORE_PACKAGE_DEPARTURE)({packageId}), data);


/**
 * Fetch package departures by package id
 */
export const fetchPackageDepartures = (packageId) => axios.get(template(FETCH_PACKAGE_DEPARTURES)({packageId}));

export const deletePackageDeparture = (packageId, departureId) => axios.delete(template(DELETE_PACKAGE_DEPARTURE)({
  packageId,
  departureId
}));

export const fetchPackageDepartureById = (packageId, departureId) => axios.get(template(FETCH_PACKAGE_DEPARTURE_DETAILS)({
  packageId,
  departureId
}));
