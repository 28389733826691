import template from 'lodash/fp/template';
import {
  FETCH_BLOGS,
  FETCH_CATEGORIES,
  FETCH_PACKAGES,
  FETCH_HOMESTAYS,
  FETCH_HOTELS,
  STORE_BLOG,
  FETCH_BLOG_DETAILS,
  UPDATE_BLOG,
  DELETE_BLOG
} from './api';

import axios from 'axios';

/**
 * Fetch Blogs
 */
export const fetchBlogs = (query) => axios.get(template(FETCH_BLOGS)({ query }));

/**
 * Fetch Packages
 */
export const fetchPackages = () => axios.get(FETCH_PACKAGES);

/**
 * Fetch Homestays
 */
export const fetchHomestays = () => axios.get(FETCH_HOMESTAYS);

/**
 * Fetch Hotels
 */
export const fetchHotels = () => axios.get(FETCH_HOTELS);

/**
 * Fetch Categories
 */
export const fetchCategories = () => axios.get(FETCH_CATEGORIES);

/**
 * Store Blog
 */
export const storeBlog = (data) => axios.post(STORE_BLOG, data);

/**
 * Fetch Blog by id.
 * 
 * @param {Integer} id 
 */
export const fetchBlogById = (id) => axios.get(template(FETCH_BLOG_DETAILS)({
  id
}));

/**
 * Update Blog.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateBlog = (id, data) => axios.patch(template(UPDATE_BLOG)({
  id
}), data);

/**
 * Delete Blog.
 * 
 * @param {*} id 
 */
export const deleteBlog = (id) => axios.delete(template(DELETE_BLOG)({
  id
}))
