export default {
    pages: {
        data: [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            title: '',
            name: '',
            uri: '',
            excerpt: ''
        },
    },
    pageImages: [],
    image_preview: [],
    parent_pages: [],

    form_dropdown: {
        template: {},
        parent_id: {}
    },

    form: {
        id: '',
        parent_id: '',
        title: '',
        slug: '',
        name: '',
        uri: '',
        excerpt: '',
        content: '',
        template: '',
        images: [],
        image_url: '',
        alt: '',
        meta_page_title: '',
        meta_keywords: '',
        meta_description: ''
    }
}
