export default {
    roles: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: ''
        },
    },
    form: {
        name: "",
        permissions: [],
    },
    permissions:[]
}