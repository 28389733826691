
export default [
    {
        path: '/blogs',
        name: 'blogs',
        component: () => import('./page/List.vue')
    },
    {
      path: '/blogs/create',
      name: 'blogs.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/blogs/:blogId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'blogs:edit',
    },
]