import axios from 'axios';
import * as storage from '../utils/storage';

/**
 * Login with username and password
 *
 * @param {object} data
 */
export const login = (data) => {
  return axios.post('/admin/login', data);
}

/**
 * Refresh the access token using the refresh token.
 */
export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw Error("Refresh Token isn't set yet");
  }

  const response = await axios.post(
    '/admin/refresh',
    {refreshToken}
  );
  const access_token = '';
  const refresh_token = '';

  storeAuthTokens({
    access_token,
    refresh_token
  });

  return access_token;
};

/**
 * Set auth tokens.
 *
 * @param {Object} tokens
 * @property access_token
 * @property refresh_token
 */
export const storeAuthTokens = ({
                                  access_token,
                                  refresh_token
                                }) => {
  storage.set('access_token', access_token);
  storage.set('refresh_token', refresh_token);
}

/**
 * Clear the tokens saved in the local storage.
 */
export const clearTokens = () => {
  storage.remove('access_token');
  storage.remove('refresh_token');
};

/**
 * Is user logged in.
 */
export const isUserLoggedIn = () => {
  if (storage.get('access_token')) return true;

  return false;
}

export const getAccessToken = () => {
  return storage.get('access_token');
}

/**
 * get refresh token from the local storage.
 *
 */
export const getRefreshToken = () => {
  return storage.get('refresh_token');
};

/**
 * Clear the user info tokens saved in the local storage.
 */
export const clearUserInfo = () => {
  storage.remove('userInfo');
};
