
export default [
    {
        path: '/pages',
        name: 'pages',
        component: () => import('./page/List.vue')
    },
    {
      path: '/pages/create',
      name: 'pages.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/pages/:pageId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'pages.edit',
    },
    // {
    //   path: '/pages/:pageId/view',
    //   component: () => import('./page/View.vue'),
    //   name: 'pages.view',
    // }
]
