import {
    fetchHomestayRegions,
    fetchAllHomestayRegions,
    storeHomestayRegion,
    fetchHomestayRegionById,
    updateHomestayRegion,
    deleteHomestayRegion
} from '../service';

import {
    SET_HOMESTAY_REGIONS,
    SET_HOMESTAY_REGIONS_DROPDOWN,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_HOMESTAY_REGION_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

import {
    changeDataToDropDownFormat
} from '../../../utils';

/**
 * Get list of homestay regions
 * 
 * @param {*} param
 */
export const fetchAndSetHomestayRegions = ({ commit }, { query }) => {
    return fetchHomestayRegions(query)
        .then((response) => {
            commit(SET_HOMESTAY_REGIONS, response.data)
            return Promise.resolve(response);
        });
}

export const createHomestayRegion = ({ commit }, data) => {
    return storeHomestayRegion(data)
        .then((response) => {
            commit;
            // commit(CLEAR_FORM)
            return Promise.resolve(response);
        });
}

export const fetchAndSetHomestayRegionById = ({ commit }, HomestayRegionId) => {
    return fetchHomestayRegionById(HomestayRegionId)
        .then((response) => {
            commit(SET_HOMESTAY_REGION_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updateHomestayRegionAction = ({ commit }, { HomestayRegionId, data }) => {
    return updateHomestayRegion(HomestayRegionId, data)
        .then((response) => {
            commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const deleteHomestayRegionAction = ({ commit }, HomestayRegionId) => {
    return deleteHomestayRegion(HomestayRegionId)
        .then((response) => {
            commit;
            return Promise.resolve(response);
        })
}
export const fetchAndSetHomestayRegionsDropdown = ({ commit }) => {
    return fetchAllHomestayRegions()
        .then((response) => {
            commit(SET_HOMESTAY_REGIONS_DROPDOWN, changeDataToDropDownFormat(response.data.data))
            return Promise.resolve(response);
        });
}

export const updateForm = ({
    commit
}, data) => commit(UPDATE_FORM, data);

export const clearForm = ({
    commit
}, data) => commit(CLEAR_FORM, data);

export const setSearchParameters = ({
    commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
}, data) => commit(CLEAR_SEARCH_PARAMETERS, data);