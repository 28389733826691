export const SET_HOMESTAY_REGIONS = 'SET_HOMESTAY_REGIONS';

export const CLEAR_FORM = 'CLEAR_FORM';

export const SET_HOMESTAY_REGION_FORM = 'SET_HOMESTAY_REGION_FORM';

export const SET_HOMESTAY_REGIONS_DROPDOWN = 'SET_HOMESTAY_REGIONS_DROPDOWN';

export const UPDATE_FORM = 'UPDATE_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
