export const FETCH_HOMESTAYS = '/v1/admin/homestays/datatable?${query}';
export const STORE_HOMESTAY = '/v1/admin/homestays';
export const FETCH_HOMESTAY_DETAILS = '/v1/admin/homestays/${id}';
export const UPDATE_HOMESTAY = '/v1/admin/homestays/${id}';
export const DELETE_HOMESTAY = '/v1/admin/homestays/${id}';
export const DELETE_HOMESTAY_IMAGE = '/v1/admin/files/${id}';
export const FETCH_USERS = '/v1/admin/vendors';
export const FETCH_COUNTRIES = '/geo/countries';
export const SET_GEO_STATES = '/geo/countries';
export const SET_GEO_CITIES = '/geo/countries';
export const SET_AMENITIES = '/v1/admin/amenities';