import {
    SET_PACKAGE_ACTIVITIES,
    CLEAR_FORM,
    SET_PACKAGE_ACTIVITY_FORM,
    SET_PACKAGE_ACTIVITY_DROPDOWN,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_PACKAGE_ACTIVITIES](state, records) {
        state.package_activities.data = records.data;
        state.package_activities.pagination.last_page = records.meta.last_page;
        state.package_activities.pagination.per_page = records.meta.per_page;
        state.package_activities.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
            name: "",
            slug: "",
            images: [],
            alt_image: "",
            description: "",
            status: true,
            meta_page_title: '',
            meta_keywords: '',
            meta_description: '',
        },
            state.image.preview = '';
        state.images = [];
        state.image.id = '';
    },

    [SET_PACKAGE_ACTIVITY_FORM](state, records) {
        state.form = {
            name: records.name,
            slug: records.slug,
            description: records.description,
            status: records.status,
            images: [],
            alt_image: (records.images != null) ? records.images[0].alt : '',
            meta_page_title: (records.meta_tag != null) ? records.meta_tag['title'] : '',
            meta_keywords: (records.meta_tag != null) ? records.meta_tag['keywords'] : '',
            meta_description: (records.meta_tag != null) ? records.meta_tag['description'] : '',
        }
        state.image.preview = (records.images != null) ? records.images[0].url : '';
        state.image.id = (records.images != null) ? records.images[0].id : '';

        state.images = [];
    },

    [SET_PACKAGE_ACTIVITY_DROPDOWN](state, records) {
        state.package_activity_dropdown = records
    },
    /**
     * Update Form.
     *
     * @param {Object} state
     * @param {*} param1
     */
    [UPDATE_FORM](state, {
        key,
        value
    }) {
        if (key == 'images') {
            if (state.images[0]) {
                state.images[0] = value;
            } else {
                state.images.push(value);
            }
            state.form.images.push(value);
        } else {
            state.form[key] = value;
        }
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
        key,
        value
    }) {
        state.search.query[key] = value;
    },

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.name = '';
    },
};
