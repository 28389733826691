import template from 'lodash/fp/template';
import {
    FETCH_HOTEL_REGIONS,
    FETCH_ALL_HOTEL_REGIONS,
    STORE_HOTEL_REGION,
    FETCH_HOTEL_REGION_DETAILS,
    UPDATE_HOTEL_REGION,
    DELETE_HOTEL_REGION
} from './api';

import axios from 'axios';

/**
 * Fetch hotel regions
 */
export const fetchHotelRegions = (query) => axios.get(template(FETCH_HOTEL_REGIONS)({ query }));

/**
 * Fetch hotel regions
 */
export const fetchAllHotelRegions = () => axios.get(FETCH_ALL_HOTEL_REGIONS);

/**
 * Store hotel regions
 */
export const storeHotelRegion = (data) => axios.post(STORE_HOTEL_REGION, data);

/**
 * Fetch hotel region by id.
 * 
 * @param {Integer} id 
 */
export const fetchHotelRegionById = (id) => axios.get(template(FETCH_HOTEL_REGION_DETAILS)({ id }));

/**
 * Update hotel region.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHotelRegion = (id, data) => axios.patch(template(UPDATE_HOTEL_REGION)({ id }), data);


export const deleteHotelRegion = (id) => axios.delete(template(DELETE_HOTEL_REGION)({ id }))
