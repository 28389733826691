import {
  SET_BLOGS,
  SET_CATEGORIES,
  SET_PACKAGES,
  SET_HOMESTAYS,
  SET_HOTELS,
  SET_BLOG_FORM,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
  [SET_BLOGS](state, records) {
    state.blogs.data = records.data;
    state.blogs.pagination.last_page = records.meta.last_page;
    state.blogs.pagination.per_page = records.meta.per_page;
    state.blogs.pagination.current_page = records.meta.current_page;
  },

  [SET_CATEGORIES](state, records) {
    state.blogCategories = records;
  },

  [SET_PACKAGES](state, records) {
    state.packages = records.data;
  },

  [SET_HOMESTAYS](state, records) {
    state.homestays = records.data;
  },

  [SET_HOTELS](state, records) {
    state.hotels = records.data;
  },

  [CLEAR_FORM](state) {
    state.form = {
      title: "",
      slug: "",
      short_description: "",
      description: "",
      blog_category_id: "",
      status: false,
      alt: '',
      packages: [],
      homestays: [],
      hotels: [],
      image: [],
      image_url: '',
      meta_page_title: '',
      meta_keywords: '',
      meta_description: '',
      position: "",
    };
    state.dropdown = {
      blog_category_id: {}
    };
  },

  [SET_BLOG_FORM](state, records) {
    let packageArray = [];
    let homestayArray = [];
    let hotelArray = [];
    if (records.packages.length > 0) {
      records.packages.map(function (p, index) {
        packageArray[p.id] = p.id;
      })
    } else {
      packageArray = [];
    }

    if (records.homestays.length > 0) {
      records.homestays.map(function (homestay, index) {
        homestayArray[homestay.id] = homestay.id;
      })
    } else {
      homestayArray = [];
    }

    if (records.hotels.length > 0) {
      records.hotels.map(function (hotel, index) {
        hotelArray[hotel.id] = hotel.id;
      })
    } else {
      hotelArray = [];
    }
    state.form = {
      title: records.title,
      slug: records.slug,
      short_description: records.short_description,
      description: records.description,
      blog_category_id: records.blog_category_id.id,
      status: records.status,
      image: [],
      packages: packageArray,
      homestays: homestayArray,
      hotels: hotelArray,
      image_url: (records.image) ? records.image[0].url : '',
      alt: (records.image) ? records.image[0].alt : '',
      meta_page_title: (records.meta_tag && records.meta_tag.title) ? records.meta_tag.title : '',
      meta_keywords: (records.meta_tag && records.meta_tag.keywords) ? records.meta_tag.keywords : '',
      meta_description: (records.meta_tag && records.meta_tag.description) ? records.meta_tag.description : '',
      position: records.position,
    };
    state.blogImages = [];
    state.alt = [];

    state.dropdown = {
      blog_category_id: {
        label: records.blog_category_id.name,
        value: records.blog_category_id.id,
      },
    };

  },

  /**
   * Update Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_FORM](state, {
    key,
    value
  }) {
    if (state.dropdown[key]) {
      state.dropdown[key] = value;
      state.form[key] = value.value;
      return;
    }
    if (key === 'image') {
      if (state.blogImages[0]) {
        state.blogImages[0] = value;
      } else {
        state.blogImages.push(value);
      }
      state.form.image.push(value);
    } else if (key === 'alt') {
      if (state.alt[0]) {
        state.alt[0] = value;
      } else {
        state.alt.push(value);
      }
      state.form[key] = value;
    } else if (key === 'packages') {
      const index = state.form.packages.indexOf(value.value);
      if (index > -1) {
        state.form.packages[value.value] = null;
      } else {
        state.form.packages[value.value] = value.value;
      }
    } else if (key === 'homestays') {
      const index = state.form.homestays.indexOf(value.value);
      if (index > -1) {
        state.form.homestays[value.value] = null;
      } else {
        state.form.homestays[value.value] = value.value;
      }
    } else if (key === 'hotels') {
      const index = state.form.hotels.indexOf(value.value);
      if (index > -1) {
        state.form.hotels[value.value] = null;
      } else {
        state.form.hotels[value.value] = value.value;
      }
    } else {

      state.form[key] = value;
    }
  },

  /**
   * Set Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [SET_SEARCH_PARAMETERS](state, {
    key,
    value
  }) {
    state.search.query[key] = value;
  },

  /**
  * Clear Search Parameters.
  *
  * @param {Object} state
  * @param {*} param1
  */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = '';
    state.search.query.title = '';
    state.search.query.blog_category_id = '';
    state.search.query.status = '';
  },
};
