import template from 'lodash/fp/template';
import {
  FETCH_BANNERS,
  STORE_BANNER,
  FETCH_BANNER_DETAILS,
  UPDATE_BANNER,
  UPDATE_BANNER_STATUS,
  DELETE_BANNER
} from './api';

import axios from 'axios';

/**
 * Fetch Banners
 */
export const fetchBanners = (query) => axios.get(template(FETCH_BANNERS)({ query }));

/**
 * Store Banner
 */
export const storeBanner = (data) => axios.post(STORE_BANNER, data);

/**
 * Fetch Banner by id.
 * 
 * @param {Integer} id 
 */
export const fetchBannerById = (id) => axios.get(template(FETCH_BANNER_DETAILS)({
  id
}));

/**
 * Update Banner.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateBanner = (id, data) => axios.patch(template(UPDATE_BANNER)({
  id
}), data);

/**
 * Update Banner Status.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateBannerStatus = (data) => axios.post(UPDATE_BANNER_STATUS, data);

/**
 * Delete Banner.
 * 
 * @param {*} id 
 */
export const deleteBanner = (id) => axios.delete(template(DELETE_BANNER)({
  id
}))
