export const SET_AMENITIES   = 'SET_AMENITIES';

export const SET_AMENITY_FORM   = 'SET_AMENITY_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';