
export default [
  {
    path: '/hotel-regions',
    name: 'hotel_regions',
    component: () => import('./page/List.vue')
  },
  {
    path: '/hotel-regions/create',
    name: 'hotel_regions.create',
    component: () => import('./page/Create.vue')
  },
  {
    path: '/hotel-regions/:hotelRegionId/edit',
    component: () => import('./page/Edit.vue'),
    name: 'hotel_regions:edit',
  }
]