export default {
    homestays: {
        data: [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: '',
            email: '',
        },
    },
    homestayImages: [
        {
            image: '',
            alt: '',
            source: [{
                source: null,
                options: {
                    type: '',
                    file: '',
                }
            }]
        }
    ],
    image_preview: "",
    editHomestayImages: [],
    geo: {
        homestay_region_id: [],
        user_id: {},
        amenities: [],
        images: "",
        alt: "",
        available_features: [],
    },
    form: {
        homestayId: "",
        name: "",
        slug: "",
        homestay_region_id: '',
        user_id: "",
        email: "",
        website: "",
        price_per_person: "",
        total_person_capicity: "",
        is_sponsored: false,
        status: true,
        is_full_payment: false,
        order: "",
        discount_persons: "",
        discount: "",
        min_payment_amount: "",
        address_1: "",
        address_2: "",
        phone_1: "",
        phone_2: "",
        description: "",
        room_description: "",
        cancellation_description: "",
        local_tourist_attraction: "",
        available_facilities: "",
        available_local_infrastructure: "",
        what_is_included: "",
        images: [],
        alt: [],
        amenities: [],
        meta_page_title: '',
        meta_keywords: '',
        meta_description: '',
        latitude: "",
        longitude: "",
        position: "",
        google_map: "",
        available_features: [],
        room_type: "",
        property_type: "",
        tag: ""
    },

    rooms: {
        data: []
    },

    roomForm: {
        roomId: "",
        name: "",
        rooms_id: "",
        rooms_type: "",
        price: "",
        max_adult: "",
        max_child: "",
        beds: "",
        room_area: "",
        bathroom_area: "",
        extra_charge: "",
        status: "",
        description: "",
        alt: [],
        images: [],
        amenities: [],
    },
    roomImages: [
        {
            image: '',
            alt: '',
            source: [{
                source: null,
                options: {
                    type: '',
                    file: '',
                }
            }]
        }
    ],
    editRoomImages: [],
    roomsMultipleData: {
        amenities: [],
        images: [],
    },
    form_dropdown: {
        room_type: {},
        property_type: {},
        tag: {}
      },
    
  tags: [
    { label: "Sponsored", value: "Sponsored" },
    { label: "Best Value", value: "Best Value" },
    { label: "Best Seller", value: "Best Seller" },
    { label: "Top Rated", value: "Top Rated" },
    { label: "Top Pick", value: "Top Pick" },
    { label: "Exclusive Offer", value: "Exclusive Offer" },
  ],
}