import template from 'lodash/fp/template';
import {
  FETCH_BLOG_CATEGORIES,
  STORE_CATEGORY,
  FETCH_CATEGORY_DETAILS,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from './api';

import axios from 'axios';

/**
 * Fetch Categories
 */
export const fetchCategories = (query) => axios.get(template(FETCH_BLOG_CATEGORIES)({ query }));

/**
 * Store Category
 */
export const storeCategory = (data) => axios.post(STORE_CATEGORY, data);

/**
 * Fetch Category by id.
 * 
 * @param {Integer} id 
 */
export const fetchCategoryById = (id) => axios.get(template(FETCH_CATEGORY_DETAILS)({
  id
}));

/**
 * Update Category.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateCategory = (id, data) => axios.patch(template(UPDATE_CATEGORY)({
  id
}), data);

/**
 * Delete Category.
 * 
 * @param {*} id 
 */
export const deleteCategory = (id) => axios.delete(template(DELETE_CATEGORY)({
  id
}))
