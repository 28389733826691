import {
    SET_BANNERS,
    SET_BANNER_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_BANNERS](state, records ) {
        state.banners.data = records.data;
        state.banners.pagination.last_page = records.meta.last_page;
        state.banners.pagination.per_page = records.meta.per_page;
        state.banners.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
            package_region_id: "",
            title: "",
            status: false,
            image: [],
            image_url: '',
            alt: '',
        };
    },

    [SET_BANNER_FORM](state, records) {
        state.form = {
            package_region_id: records.region.id,
            title: records.title,
            status: records.status,
            image: [],
            alt: (records.images) ? records.images[0].alt : '',
            image_url: records.images[0].url,
        };
        state.bannerImages = [];
        state.alt = [];
        state.form_dropdown = {
          package_region_id: {
              label: records.region.name,
              value: records.region.id
          },
        }
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      if(state.form_dropdown[key]) {
        state.form_dropdown[key] = value;
        state.form[key] = value.value;
        return;
      }

      if( key== 'image'){
        if(state.bannerImages[0]){
          state.bannerImages[0] = value;
        }else{
            state.bannerImages.push(value);
        }
        state.form.image.push(value);
      }else if( key== 'alt'){
        if(state.alt[0]){
          state.alt[0] = value;
        }else{
            state.alt.push(value);
        }
        state.form[key] = value;
      }else {

        state.form[key] = value;
      }
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.title = '';
        state.search.query.status = '';
    },
};