export default {
    user: null,
    form: {
        id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        username: "",
    },

    passwordForm: {
        old_password: "",
        new_password: "",
        confirm_password: "",
    }
}