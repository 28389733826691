
import {
    FETCH_ACTIVITIES
} from './api';

import axios from 'axios';

/**
 * Fetch hotels
 */
export function fetchActivities() {
    return axios.get(FETCH_ACTIVITIES);
}