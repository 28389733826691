import {
    fetchHotels,
    storeHotel,
    fetchHotelById,
    updateHotel,
    deleteHotel,
} from '../service';

import {
    /* Hotel Room services */
    storeHotelRoom,
    deleteHotelRoom,
    fetchHotelRoomById,
    updateHotelRoom,
} from '../rooms/service';

import {
    SET_HOTELS,
    UPDATE_FORM,
    SET_HOTEL_FORM,
    CLEAR_FORM,
    /* Hotel rooms */
    CLEAR_ROOM_FORM,
    UPDATE_ROOM_FORM,
    SET_HOTEL_ROOM_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS,
} from '../mutation-types';

/**
 * Get list of hotels
 * 
 * @param {*} param0 
 */
export const fetchAndSetHotels = ({ commit }, { query }) => {
    return fetchHotels(query)
        .then((response) => {
            commit(SET_HOTELS, response.data)
            return Promise.resolve(response);
        });
}

/**
 * create hotel
 * 
 * @param {*} param0 
 */
export const createHotel = ({ commit }, data) => {
    return storeHotel(data)
        .then((response) => {
            return Promise.resolve(response);
        });
}

/**
 * Find Single hotel
 * 
 * @param {*} param0 
 */
export const fetchAndSetHotelById = ({ commit }, hotelId) => {
    return fetchHotelById(hotelId)
        .then((response) => {
            commit(SET_HOTEL_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

/**
 * Update Hotel Data
 * 
 * @param {Integer} id 
 */
export const updateHotelAction = ({ commit }, { hotelId, data }) => {
    return updateHotel(hotelId, data)
        .then((response) => {
            return Promise.resolve(response);
        });
}

/**
 * Delete Hotel Data
 * 
 * @param {Integer} id 
 */
export const deleteHotelAction = ({ commit }, hotelId) => {
    return deleteHotel(hotelId)
        .then((response) => {
            return Promise.resolve(response);
        })
}



export const updateForm = ({ commit }, data) => commit(UPDATE_FORM, data);

export const clearForm = ({ commit }) => commit(CLEAR_FORM);

/**
 * Hotel Rooms Modules actions
 * 
 * @param {*} param0 
 */
export const updateRoomForm = ({ commit }, data) => commit(UPDATE_ROOM_FORM, data);


export const clearRoomForm = ({ commit }) => commit(CLEAR_ROOM_FORM);

export const createHotelRoom = ({ commit }, { hotelId, data }) => {
    return storeHotelRoom(hotelId, data)
        .then((response) => {
            if (response.status_code == 201) {
                commit(CLEAR_ROOM_FORM)
            }
            return Promise.resolve(response);
        });
}

export const deleteHotelRoomAction = ({ commit }, { hotelId, roomId }) => {
    return deleteHotelRoom(hotelId, roomId)
        .then((response) => {
            return Promise.resolve(response);
        });
}

export const fetchAndSetHotelRoomById = ({ commit }, { hotelId, roomId }) => {
    return fetchHotelRoomById(hotelId, roomId)
        .then((response) => {
            commit(SET_HOTEL_ROOM_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updateHotelRoomAction = ({ commit }, { hotelId, roomId, data }) => {
    return updateHotelRoom(hotelId, roomId, data)
        .then((response) => {
            commit(CLEAR_ROOM_FORM);
            return Promise.resolve(response);
        })
}

export const setSearchParameters = ({
    commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
}, data) => commit(CLEAR_SEARCH_PARAMETERS, data);