
export default [
    {
        path: '/seo-pages',
        name: 'seo-pages',
        component: () => import('./page/List.vue')
    },
    {
      path: '/seo-pages/create',
      name: 'seo-pages.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/seo-pages/:seoPageId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'seo-pages:edit',
    },
]