export const SET_PACKAGES = 'SET_PACKAGES';

export const CLEAR_FORM = 'CLEAR_FORM';

export const SET_PACKAGE_FORM = 'SET_PACKAGE_FORM';

export const SET_VENDORS = 'SET_VENDORS';

export const UPDATE_FORM = 'UPDATE_FORM';

/* Itenary */
export const UPDATE_ITENARY_FORM = 'UPDATE_ITENARY_FORM';
export const CLEAR_ITENARY_FORM = 'CLEAR_ITENARY_FORM';
export const SET_PACKAGE_ITENARY_FORM = 'SET_PACKAGE_ITENARY_FORM';

/* Departure */
export const UPDATE_DEPARTURE_FORM = 'UPDATE_DEPARTURE_FORM';
export const SET_PACKAGE_DEPARTURE_FORM = 'SET_PACKAGE_DEPARTURE_FORM';
export const SET_PACKAGE_DEPARTURES = 'SET_PACKAGE_DEPARTURES';
export const CLEAR_DEPARTURE_FORM = 'CLEAR_DEPARTURE_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
