/**
 * Validation
 * @type {string}
 */
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const CLEAR_VALIDATION_ERROR = 'CLEAR_VALIDATION_ERROR';

/**
 * Vendors
 */
export const SET_VENDORS_DROPDOWN = 'SET_VENDORS';

export const SET_DEPARTURE_TYPES_DROPDOWN = 'SET_DEPARTURE_TYPES_DROPDOWN';

export const SET_PACKAGE_TYPES_DROPDOWN = 'SET_PACKAGE_TYPES_DROPDOWN';

export const SET_LISTING_TYPES_DROPDOWN = 'SET_LISTING_TYPES_DROPDOWN';

export const SET_COUNTRIES_DROPDOWN = 'SET_COUNTRIES_DROPDOWN';

export const SET_STATES_DROPDOWN = 'SET_STATES_DROPDOWN';

export const SET_CITIES_DROPDOWN = 'SET_CITIES_DROPDOWN';

export const SET_USERS_DROPDOWN = 'SET_USERS_DROPDOWN';

export const SET_AMENITIES_DROPDOWN = 'SET_AMENITIES_DROPDOWN';

export const SET_FEATURES_DROPDOWN = 'SET_FEATURES_DROPDOWN';

export const SET_TEMPLATE_DROPDOWN = 'SET_TEMPLATE_DROPDOWN';

export const SET_ROOM_TYPES_DROPDOWN = 'SET_ROOM_TYPES_DROPDOWN';

export const SET_PROPERTY_TYPES_DROPDOWN = 'SET_PROPERTY_TYPES_DROPDOWN';
