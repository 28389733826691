import {
    fetchActivities
} from '../service';
import {changeDataToDropDownFormat} from '../../../utils/index'

import {
    SET_ACTIVITIES_DROPDOWN
} from '../mutation-types';

/**
 * Get list of hotels
 * 
 * @param {*} param0 
 */
// export const fetchAndSetHotels = ({ commit }) => {
//     return fetchHotels()
//         .then((response) => {
//             commit(SET_HOTELS, response.data)
//             return Promise.resolve(response);
//         });
// }

export const fetchAndSetActivitiesDropDown = ({ commit }) => {
    return fetchActivities()
        .then((response) => {
            commit(SET_ACTIVITIES_DROPDOWN, changeDataToDropDownFormat(response.data.data));
            return Promise.resolve(response);
        });
}

/**
 * create hotel
 * 
 * @param {*} param0 
 */
// export const createHotel = ({ commit }, data) => {
//     return storeHotel(data)
//         .then((response) => {
//             commit(CLEAR_FORM)
//             return Promise.resolve(response);
//         });
// }