import {
  SET_PAGES,
  CLEAR_FORM,
  SET_PAGE_FORM,
  UPDATE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_PARENT_PAGE_DROPDOWN, UPDATE_DROPDOWN_FORM
} from '../mutation-types';

export default {
  /**
   * Set package regions.
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_PAGES](state, records) {
    state.pages.data = records.data;
    state.pages.pagination.last_page = records.meta.last_page;
    state.pages.pagination.per_page = records.meta.per_page;
    state.pages.pagination.current_page = records.meta.current_page;
  },

  [CLEAR_FORM](state) {
    state.form = {
      id: '',
      parent_id: '',
      title: '',
      slug: '',
      name: '',
      uri: '',
      excerpt: '',
      content: '',
      template: '',
      images: [],
      image_url: '',
      alt: '',
      meta_page_title: '',
      meta_keywords: '',
      meta_description: ''
    };

    state.pageImages = [];
  },

  [SET_PAGE_FORM](state, records) {
    if (records.images) {
      state.image_preview = records.images[0].url;
    }
    state.form = {
      id: records.id,
      parent_id: (records.parent_id) ? records.parent_id.id : '',
      slug: records.slug,
      title: records.title,
      name: records.name,
      uri: records.uri,
      excerpt: records.excerpt,
      content: records.description,
      images: [],
      image_url: (records.images) ? records.images[0].url : '',
      template: records.template_type,
      meta_page_title: (records.meta_tag) ? records.meta_tag.title : '',
      meta_keywords: (records.meta_tag) ? records.meta_tag.keywords : '',
      meta_description: (records.meta_tag) ? records.meta_tag.description : ''
    };
    state.pageImages = [];

    state.form_dropdown = {
      parent_id: {
        label: (records.parent_id) ? records.parent_id.name : 'Search and Select',
        value: (records.parent_id) ? records.parent_id.id : ''
      },
      template: records.template_type
    }
  },

  /**
   * Update cms Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_FORM](state, { key, value }) {
    if (state.form_dropdown[key]) {
      state.form_dropdown[key] = value;
      state.form[key] = value.value;
    } else if (key === 'images') {
      if (state.pageImages[0]) {
        state.pageImages[0] = value;
      } else {
        state.pageImages.push(value);
      }
      state.form.images.push(value);
    } else {
      state.form[key] = value;
    }
  },

  /**
   * Update cms dropdown Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_DROPDOWN_FORM](state, { key, value }) {
    if (state.form_dropdown[key]) {
      state.form_dropdown[key] = value;
      state.form[key] = value.value;
    } else {
      state.form[key] = value;
    }
  },
  /**
   * Set Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [SET_SEARCH_PARAMETERS](state, {
    key,
    value
  }) {
    state.search.query[key] = value;
  },
  /**
   * Clear Search Parameters.
   *
   * @param {Object} state
   */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = '';
    state.search.query.name = '';
    state.search.query.slug = '';
  },

  [SET_PARENT_PAGE_DROPDOWN](state, records) {
    state.parent_pages = records;
  }
};
