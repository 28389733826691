export default {
    hotels: {
        data: null,
        pagination: {
            last_page: 0
        }
    },
    activities_dropdown: [],
    form: {
        name: "",
        slug: "",
        alt_image: "",
        description: "",
        display_home: true,
        status: true,
        images: [],
        meta_page_title: '',
        meta_keywords: '',
        meta_description: '',
    }
}