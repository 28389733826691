import template from 'lodash/fp/template';
import {
    STORE_HOMESTAY_ROOM,
    FETCH_HOMESTAY_ROOM_DETAILS,
    UPDATE_HOMESTAY_ROOM,
    DELETE_HOMESTAY_ROOM,
    DELETE_HOMESTAY_ROOM_IMAGE,
} from './api';


import axios from 'axios';



/**
 * Store Homestay Rooms
 */
export const storeHomeStayRoom = (homestayId, data) => axios.post(template(STORE_HOMESTAY_ROOM)({ homestayId }), data);

/**
 * Delete HomeStay Room Data
 * 
 * @param {Integer} id 
 */
export const deleteHomeStayRoom = (homestayId, roomId) => axios.delete(template(DELETE_HOMESTAY_ROOM)({ homestayId, roomId }));



/**
 * Fetch HomeStay Rooms by id.
 * 
 * @param {Integer} id 
 */
export const fetchHomeStayRoomById = (homestayId, roomId) => axios.get(template(FETCH_HOMESTAY_ROOM_DETAILS)({ homestayId, roomId }));

/**
 * Update HomeStay rooms.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHomeStayRoom = (homestayId, roomId, data) => axios.patch(template(UPDATE_HOMESTAY_ROOM)({ homestayId, roomId }), data);


/**
 * Delete HomeStay room Image
 * 
 * @param {Integer} id 
 */

export const deleteHomeStayRoomImage = (id) => axios.delete(template(DELETE_HOMESTAY_ROOM_IMAGE)({ id }));