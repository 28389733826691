import {
    SET_ROOM_TYPES_DROPDOWN
} from './mutation-type';

import {fetchRoomTypes} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    room_types_dropdown: [],
};

const getters = {
    fetchRoomTypesDropdown : (state) => state.room_types_dropdown,
};

const actions = {
    fetchAndSetRoomTypesDropdown({ commit }) {
        fetchRoomTypes().then((response) => {
            commit(SET_ROOM_TYPES_DROPDOWN, changeDataToDropDownFormat(response.data.data));
        });
    }
};

const mutations = {
    [SET_ROOM_TYPES_DROPDOWN](state, records ) {
        state.room_types_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
