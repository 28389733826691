import {
    SET_ROLES,
    SET_PERMISSIONS,
    SET_ROLE_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_ROLES](state, records ) {
        state.roles.data = records.data;
        state.roles.pagination.last_page = records.meta.last_page;
        state.roles.pagination.per_page = records.meta.per_page;
        state.roles.pagination.current_page = records.meta.current_page;
    },

    [SET_PERMISSIONS](state, records ) {
        state.permissions = records.data;
    },

    [CLEAR_FORM](state) {
        state.form = {
            name: "",
            permissions: [],
        };
    },

    [SET_ROLE_FORM](state, records) {
        state.form.name = records.name;
        if(records.permissions.length > 0){
            records.permissions.map(function(perm, index){
              state.form.permissions[perm.id] = perm.id;
            })
        }else{
          state.form.permissions = [];
        }
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      if( key== 'permissions'){
        const index = state.form.permissions.indexOf(value.value);
        if(index > -1){
          state.form.permissions[value.value] = null;
        }else{
          state.form.permissions[value.value] = value.value;
        }
      }else{
        state.form[key] = value;
      }
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.name = '';
    },
};