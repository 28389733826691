
export default [
    {
        path: '/banners',
        name: 'banners',
        component: () => import('./page/List.vue')
    },
    {
      path: '/banners/create',
      name: 'banners.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/banners/:bannerId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'banners:edit',
    },
]