/**
 * Get list of bookings
 * 
 * @param {object} state 
 */
export const getBookings = (state) => {
    return state.bookings;
}


export const getSearchObj = (state) => {
    return state.search;
}

export const getBookingObj = (state) => {
    return state.booking;
  }


