export default {
    topdeals: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
            total_records:0,
        },
    },
    search: {
        query: {
            id: '',
            title: '',
            subtitle: '',
            type: '',
        },
    },
    form: {
        title: "",
        subtitle: "",
        type: "",
        image: [],
        image_url: '',
    },
    types: [
        {label: 'Section 1', value: 'section-1'},
        {label: 'Section 2', value: 'section-2'},
        {label: 'Section 3', value: 'section-3'}
    ],
    dropdown:{
        type:{}
    },
    
    topdealImages:[]
}