import {
  fetchTopDeals,
  storeTopDeal,
  fetchTopDealById,
  updateTopDeal,
  deleteTopDeal
} from '../service';

import {
  SET_TOP_DEALS,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_TOP_DEAL_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Top Deals
 * 
 * @param {*} param0 
 */
export const fetchAndSetTopDeals = ({
  commit
},  {query}) => {
  return fetchTopDeals(query)
    .then((response) => {
      commit(SET_TOP_DEALS, response.data)
      return Promise.resolve(response);
    });
}

export const createTopDeal = ({
  commit
}, data) => {
  return storeTopDeal(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetTopDealById = ({
  commit
}, topdealId) => {
  return fetchTopDealById(topdealId)
    .then((response) => {
      commit(SET_TOP_DEAL_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateTopDealAction = ({
  commit
}, {
  topdealId,
  data
}) => {
  return updateTopDeal(topdealId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteTopDealAction = ({
  commit
}, topdealId) => {
  return deleteTopDeal(topdealId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);