
export default [
  {
      path: '/package-activities',
      name: 'package_activities',
      component: () => import('./page/List.vue')
  },
  {
    path: '/package-activities/create',
    name: 'package_activities.create',
    component: () => import('./page/Create.vue')
  },
  {
    path: '/package-activities/:packageActivityId/edit',
    component: () => import('./page/Edit.vue'),
    name: 'package_activities:edit',
  }
]