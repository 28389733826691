
/**
 * Get list of amenities
 * 
 * @param {object} state 
 */
export const getamenities = (state) => {
    return state.amenities;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getAmenityTypes = (state) => {
    return state.types;
}

export const getDropdownObj = (state) => {
    return state.dropdown;
}


export const getImages = (state) => {
    return state.amenityImages;
}