import {
  fetchBanners,
  storeBanner,
  fetchBannerById,
  updateBanner,
  updateBannerStatus,
  deleteBanner
} from '../service';

import {
  SET_BANNERS,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_BANNER_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Banners
 * 
 * @param {*} param0 
 */
export const fetchAndSetBanners = ({
  commit
},  {query}) => {
  return fetchBanners(query)
    .then((response) => {
      commit(SET_BANNERS, response.data)
      return Promise.resolve(response);
    });
}

export const createBanner = ({
  commit
}, data) => {
  return storeBanner(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetBannerById = ({
  commit
}, bannerId) => {
  return fetchBannerById(bannerId)
    .then((response) => {
      commit(SET_BANNER_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateBannerStatusAction = ({
  commit
}, {
  data
}) => {
  return updateBannerStatus(data)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const updateBannerAction = ({
  commit
}, {
  bannerId,
  data
}) => {
  return updateBanner(bannerId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteBannerAction = ({
  commit
}, bannerId) => {
  return deleteBanner(bannerId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);