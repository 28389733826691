import {
    SET_BLOG_CATEGORIES,
    SET_BLOG_CATEGORY_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_BLOG_CATEGORIES](state, records ) {
        state.categories.data = records.data;
        state.categories.pagination.last_page = records.meta.last_page;
        state.categories.pagination.per_page = records.meta.per_page;
        state.categories.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
            name: "",
            slug: "",
            display_name: "",
            status: false,
        };
    },

    [SET_BLOG_CATEGORY_FORM](state, records) {
        state.form = {
            name: records.name,
            slug: records.slug,
            display_name: records.display_name,
            status: records.status,
        };
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      state.form[key] = value;
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.name = '';
        state.search.query.display_name = '';
    },
};