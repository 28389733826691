import {
    SET_USERS_DROPDOWN
} from './mutation-type';

import {fetchUsers} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    users_dropdown: [],
};

const getters = {
    fetchUsersDropdown : (state) => state.users_dropdown,
};

const actions = {
    fetchAndSetUsersDropdown({ commit }) {
        fetchUsers().then((response) => {
            commit(SET_USERS_DROPDOWN, changeDataToDropDownFormat(response.data.data, 'full_name'));
        });
    }
};

const mutations = {
    [SET_USERS_DROPDOWN](state, records ) {
        state.users_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
