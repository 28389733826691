export default {
    notices: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            title: '',
            type: '',
        },
    },
    form: {
        title: "",
        description: "",
        type: "",
        status: true,
    },
    types: [
        {label: 'Hotel', value: 'hotel'},
        {label: 'Homestay', value: 'homestay'},
        {label: 'Package', value: 'package'}
    ],
    dropdown:{
        type:{}
    },
}