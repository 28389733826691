export default {
    package_activities: {
        data: [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    search: {
        query: {
            id: '',
            name: ''
        },
    },
    image: {
        preview: null,
        id: null
    },
    form: {
        name: "",
        slug: "",
        alt_image: "",
        status: true,
        description: "",
        images: [],
        meta_page_title: '',
        meta_keywords: '',
        meta_description: '',
    },
    package_activity_dropdown: {},
    images: []
}