export const SET_ROLES   = 'SET_ROLES';

export const SET_PERMISSIONS   = 'SET_PERMISSIONS';

export const SET_ROLE_FORM   = 'SET_ROLE_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';