import template from 'lodash/fp/template';
import {
    STORE_PACKAGE_ITENARY,
    FETCH_PACKAGE_ITENARY_DETAILS,
    UPDATE_PACKAGE_ITENARY,
    DELETE_PACKAGE_ITENARY,
} from './api';


import axios from 'axios';



/**
 * Store PACKAGE ITENARYs
 */
export const storePackageItenary = (packageId, data) => axios.post(template(STORE_PACKAGE_ITENARY)({ packageId }), data);

/**
 * Delete PACKAGE ITENARY Data
 * 
 * @param {Integer} id 
 */
export const deletePackageItenary = (packageId, itenaryId) => axios.delete(template(DELETE_PACKAGE_ITENARY)({ packageId, itenaryId }));



/**
 * Fetch PACKAGE ITENARYs by id.
 * 
 * @param {Integer} id 
 */
export const fetchPackageItenaryById = (packageId, itenaryId) => axios.get(template(FETCH_PACKAGE_ITENARY_DETAILS)({ packageId, itenaryId }));

/**
 * Update PACKAGE ITENARYs.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updatePackageItenary = (packageId, itenaryId, data) => axios.patch(template(UPDATE_PACKAGE_ITENARY)({ packageId, itenaryId }), data);


/**
 * Delete PACKAGE ITENARY Image
 * 
 * @param {Integer} id 
 */

export const deletePackageItenaryImage = (id) => axios.delete(template(DELETE_PACKAGE_ITENARY_IMAGE)({ id }));