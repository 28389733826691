import {
    SET_AMENITIES,
    SET_AMENITY_FORM,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_AMENITIES](state, records ) {
        state.amenities.data = records.data;
        state.amenities.pagination.last_page = records.meta.last_page;
        state.amenities.pagination.per_page = records.meta.per_page;
        state.amenities.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
            name: "",
            display_name: "",
            type: "",
            status: true,
            images: [],
        };
        
        state.dropdown.type=[]
    },

    [SET_AMENITY_FORM](state, records) {
        state.form = {
            name: records.name,
            display_name: records.display_name,
            type: records.type,
            status: records.status,
        };
        state.amenityImages = (records.images != null) ? records.images[0]: [];
        
        state.dropdown = {
          type:  {
              label: records.type,
              value: records.type,
          },
      }
    },

    /**
     * Update Form.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [UPDATE_FORM](state, {
      key,
      value
    }) {
      if (state.dropdown[key]) {
        state.dropdown[key] = value;
        state.form[key] = value.value;
        return;
      }
      if( key== 'images') {
        if(state.amenityImages[0]) {
          state.amenityImages[0] = value;
        }else{
            state.amenityImages.push(value);
        }
      }else{
        state.form[key] = value;
      }
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.name = '';
        state.search.query.display_name = '';
        state.search.query.type = '';
    },
};