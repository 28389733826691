import {
    SET_HOTELS,
    CLEAR_FORM,
    SET_ACTIVITIES_DROPDOWN
} from '../mutation-types';

export default {
    [SET_HOTELS](state, records) {
        state.hotels.data = records.data;
        state.hotels.pagination = records.meta;
        state.hotels.links = records.links;
    },

    /**
     * Set activities dropdown.
     * 
     * @param {Object} state 
     * @param {Array} records 
     */
    [SET_ACTIVITIES_DROPDOWN](state, records) {
        state.activities_dropdown = records
    },

    [CLEAR_FORM](state) {
        state.hotels.form.name = '';
        state.hotels.form.slug = '';
        state.hotels.form.alt_image = '';
        state.hotels.form.description = '';
        state.hotels.form.display_home = false;
        state.hotels.form.status = false;
        state.hotels.form.images = [];
        state.hotels.form.meta_page_title = '';
        state.hotels.form.meta_keywords = '';
        state.hotels.form.meta_description = '';
    }
};