/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
    Author: HopNepal
    Author URL: https://www.hopnepal.com
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import role from '../app/role/store'
import user from '../app/user/store'
import package_region from '../app/package-region/store'
import hotel_region from '../app/hotel-region/store'
import package_activity from '../app/package-activity/store'
import main_package from '../app/package/store'
import amenity from '../app/amenity/store'
import homestay from '../app/homestay/store'
import hotel from '../app/hotel/store'
import validation from '../app/common/validation'
import vendor from '../app/common/vendor'
import user_dropdown from '../app/common/user'
import homestay_amenities from '../app/common/homestay_amenities'
import countries from '../app/common/countries'
import activity from '../app/activity/store'
import departure_type from '../app/common/departure-type'
import package_type from '../app/common/package-type'
import listing_type from '../app/common/listing-type'
import template_type from '../app/common/template-type'
import banner from '../app/banner/store'
import setting from '../app/settings/store'
import topdeals from '../app/topdeals/store'
import blog_category from '../app/blog/category/store'
import blog from '../app/blog/store'
import cms from '../app/cms/store'
import homestay_region from '../app/homestay-region/store'
import feature from '../app/feature/store'
import feature_dropdown from '../app/common/features'
import notice from '../app/notice/store'
import seo_page from '../app/seo-page/store'
import room_type from '../app/common/room-type'
import property_type from '../app/common/property-type'
import booking from '../app/booking/store'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    role,
    user,
    countries,
    user_dropdown,
    package_region,
    package_activity,
    main_package,
    validation,
    vendor,
    activity,
    departure_type,
    package_type,
    listing_type,
    template_type,
    hotel,
    homestay,
    homestay_region,
    homestay_amenities,
    amenity,
    banner,
    hotel_region,
    topdeals,
    blog_category,
    blog,
    setting,
    cms,
    feature,
    feature_dropdown,
    notice,
    seo_page,
    room_type,
    property_type,
    booking
  },
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'local'
})
