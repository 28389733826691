/**
 * Get list of notices
 * 
 * @param {object} state 
 */
export const getNotices = (state) => {
    return state.notices;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getNoticeTypes = (state) => {
    return state.types;
}

export const getDropdownObj = (state) => {
    return state.dropdown;
}

