
export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('./page/List.vue')
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('./page/Create.vue')
  },
  {
    path: '/users/:userId/edit',
    component: () => import('./page/Edit.vue'),
    name: 'users:edit',
  },
  {
    path: '/users/:userId/profile',
    component: () => import('./page/Profile.vue'),
    name: 'users:profile',
  }
]