import axios from 'axios';

import {redirect} from '@/utils';
import {clearTokens, refreshAccessToken} from '@/services/auth';
import {UNAUTHORIZED, UNPROCESSABLE_ENTITY} from '@/constant/response-status-code';

export default class Interceptors {
    static  init(store) {
        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response.status === UNPROCESSABLE_ENTITY) {
                    store.dispatch('validation/setValidationErrors', error.response.data.errors)
                }

                const originalRequest = error.response.config;
                const code = error.response && error.response.status;

                if (originalRequest['__isRetryRequest']) {
                  clearTokens();

                  // eslint-disable-next-line no-undef
                  return Promise.reject(err);
                }

                if (code === UNAUTHORIZED) {
                  originalRequest.__isRetryRequest = true;

                  try {
                    const accessToken = await refreshAccessToken();

                    const newRequest = {
                      ...originalRequest,
                      headers: {
                        ...originalRequest.headers,
                        // eslint-disable-next-line no-undef
                        [httpConstants.authorizationHeader]: `Bearer ${accessToken}`,
                      },
                    };

                    return axios(newRequest);
                  } catch (error) {
                    clearTokens();
                    redirect('/');
                  }
                }


                return Promise.reject(error);
            });
    }
}
