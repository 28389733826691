
export default [
    {
        path: '/amenities',
        name: 'amenities',
        component: () => import('./page/List.vue')
    },
    {
      path: '/amenities/create',
      name: 'amenities.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/amenities/:amenityId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'amenities:edit',
    },
]