import {
  fetchAmenities,
  storeAmenity,
  fetchAmenityById,
  updateAmenity,
  deleteAmenity
} from '../service';

import {
  SET_AMENITIES,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_AMENITY_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

/**
 * Get list of Amenitys
 * 
 * @param {*} param0 
 */
export const fetchAndSetAmenities = ({
  commit
},  {query}) => {
  return fetchAmenities(query)
    .then((response) => {
      commit(SET_AMENITIES, response.data)
      return Promise.resolve(response);
    });
}

export const createAmenity = ({
  commit
}, data) => {
  return storeAmenity(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetAmenityById = ({
  commit
}, amenityId) => {
  return fetchAmenityById(amenityId)
    .then((response) => {
      commit(SET_AMENITY_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updateAmenityAction = ({
  commit
}, {
  amenityId,
  data
}) => {
  return updateAmenity(amenityId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deleteAmenityAction = ({
  commit
}, amenityId) => {
  return deleteAmenity(amenityId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

export const clearForm = ({commit}) => commit(CLEAR_FORM);

export const updateForm = ({
  commit
}, data) => commit(UPDATE_FORM, data);

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);