
/**
 * Get list of hotel regions
 * 
 * @param {object} state 
 */
export const getHotelRegions = (state) => state.hotel_regions;

export const getFormObj = (state) => state.form;

export const getHotelRegionsDropdown = (state) => state.hotel_regions_dropdown;

export const getImagePreview = (state) => state.image_preview;

export const getSearchObj = (state) => state.search;