import state from "./state";

/**
 * Get list of Homestays
 *
 * @param {object} state
 */
export const getHomeStays = (state) => {
  return state.homestays;
};

export const getHomeStayImages = (state) => {
  return state.homestayImages;
};

export const editHomeStayImages = (state) => {
  return state.editHomestayImages;
};

export const getFormObj = (state) => {
  return state.form;
};

export const getHomestayRegionsDropdown = (state) => {
  //
};

export const getFromGeo = (state) => {
  return state.geo;
};

export const getFromAmenities = (state) => {
  return state.geo.amenities;
};

export const previewImage = (state) => {
  return state.image_preview;
};

export const getSearchObj = (state) => {
  return state.search;
};

/**
 * Get list of Homestay Rooms
 *
 * @param {object} state
 */

export const getFromHomestayRooms = (state) => {
  return state.rooms;
};

export const getHomestayRoomImages = (state) => {
  return state.roomImages;
};

export const editHomeStayRoomImages = (state) => {
  return state.editRoomImages;
};

export const getRoomFormObj = (state) => {
  return state.roomForm;
};

export const getRoomFormObjDropDown = (state) => {
  return state.roomsMultipleData;
};

/**
 * Get Features list
 *
 * @param {object} state
 */
export const getFeatures = (state) => {
  return state.geo.available_features;
};

export const getFormDropdownObj = (state) => {
  return state.form_dropdown;
};

export const getTags = (state) => {
  return state.tags;
};
