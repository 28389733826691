
/**
 * Get list of amenities
 * 
 * @param {object} state 
 */
export const getBanners = (state) => {
    return state.banners;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

export const getSearchObj = (state) => {
    return state.search;
}

export const getAmenityTypes = (state) => {
    return state.types;
}


export const getImages = (state) => {
    return state.bannerImages;
}

export const getImageAlt = (state) => {
    return state.alt;
}

export const getFormDropdownObj = (state) => {
    return state.form_dropdown;
}