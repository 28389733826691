
/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getPasswordFormObj = (state) => {
    return state.passwordForm;
}