import template from 'lodash/fp/template';
import {
    FETCH_USERS,
    FETCH_USER_TYPES,
    STORE_USER,
    FETCH_USER_DETAILS,
    UPDATE_USER,
    DELETE_USER,
    UPDATE_PROFILE
} from './api';

import axios from 'axios';

/**
 * Fetch users
 */
export const fetchUsers = (query) => axios.get(template(FETCH_USERS)({ query }));

export const fetchUserTypes = () => axios.get(FETCH_USER_TYPES);

/**
 * Store users
 */
export const storeUser = (data) => axios.post(STORE_USER, data);

/**
 * Fetch user by id.
 * 
 * @param {Integer} id 
 */
export const fetchUserById = (id) => axios.get(template(FETCH_USER_DETAILS)({ id }));

/**
 * Update user.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateUser = (id, data) => axios.patch(template(UPDATE_USER)({ id }), data);

/**
 * Update user.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateProfile = (id, data) => axios.post(template(UPDATE_PROFILE)({ id }), data);


export const deleteUser = (id) => axios.delete(template(DELETE_USER)({ id }))
