import {
    SET_USERS,
    CLEAR_FORM,
    UPDATE_FORM,
    SET_USER_FORM,
    SET_USER_TYPES_DROPDOWN,
    SET_PROFILE_FORM,
    UPDATE_PROFILE_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS,
} from '../mutation-types';

export default {
    [SET_USERS](state, records ) {
        state.users.data = records.data;
        state.users.pagination.last_page = records.meta.last_page;
        state.users.pagination.per_page = records.meta.per_page;
        state.users.pagination.current_page = records.meta.current_page;
    },

    [CLEAR_FORM](state) {
        state.form = {
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
          username: '',
          status: false,
        };
        state.form_dropdown = {}
    },

    [SET_USER_FORM](state, records) {
        state.form = {
            first_name: records.first_name,
            middle_name: records.middle_name,
            last_name: records.last_name,
            email: records.email,
            username: records.username,
            status: records.status
        };
        state.form_dropdown = {
            role_id: {
                label: (typeof records.role !== 'undefined') ? records.role[0].name : '',
                value: (typeof records.role !== 'undefined') ? records.role[0].id : ''
            }
        }
    },

    [SET_PROFILE_FORM](state, records) {
        let profile_image = '';
        let vat_image = '';
        let pan_image = '';
        let tan_image = '';
        if(records.media){
          records.media.each(function(image, index){
            switch (image.alt) {
              case 'profile':
                profile_image = image.url;
                break;
              case 'pan':
                pan_image = image.url;
                break;
              case 'tan':
                tan_image = image.url;
                break;
              case 'vat':
                vat_image = image.url;
                break;
            }
          });
        }
        state.profile_form = {
            address_1: records.address_1,
            address_2: records.address_2,
            citizenship_number: records.citizenship_number,
            company_name: records.company_name,
            employees: records.employees,
            established_at: records.established_at,
            pan_number: records.pan_number,
            phone_1: records.phone_1,
            phone_2: records.phone_2,
            tan_number: records.tan_number,
            vat_number: records.vat_number,
            image: [],
            pan: [],
            vat: [],
            tan: [],
            media: records.media,
            image_url: profile_image,
            pan_image_url: pan_image,
            vat_image_url: vat_image,
            tan_image_url: tan_image
        };
        state.profileImages = [];
    },

    [SET_USER_TYPES_DROPDOWN] (state, records) {
        state.user_types = records
    },

     /**
      * Update Form.
      * 
      * @param {Object} state 
      * @param {*} param1 
      */
     [UPDATE_FORM](state, {
       key,
       value
     }) {
       if (state.form_dropdown[key]) {
         state.form_dropdown[key] = value;
         state.form[key] = value.value;
         return;
       }

       state.form[key] = value;
     },

     /**
      * Update profile Form.
      * 
      * @param {Object} state 
      * @param {*} param1 
      */
     [UPDATE_PROFILE_FORM](state, {
       key,
       value
     }) {
      if( key == 'image'){
        if(state.profileImages[value.index]){
          state.profileImages[value.index] = value.file;
          state.imageAlts[value.index] = value.alt;
        }else{
          state.profileImages[value.index] = value.file;
          state.imageAlts[value.index] = value.alt;
        }
        switch (value.alt) {
          case 'profile':
            if(state.profile_form.image[value.index]){
              state.profile_form.image[value.index] = value.file;
            }else{
              state.profile_form.image.push(value.file);
            }
            break;
          case 'pan':
            if(state.profile_form.pan[value.index]){
              state.profile_form.pan[value.index] = value.file;
            }else{
              state.profile_form.pan.push(value.file);
            }
            break;
          case 'tan':
            if(state.profile_form.tan[value.index]){
              state.profile_form.tan[value.index] = value.file;
            }else{
              state.profile_form.tan.push(value.file);
            }
            break;
          case 'vat':
            if(state.profile_form.vat[value.index]){
              state.profile_form.vat[value.index] = value.file;
            }else{
              state.profile_form.vat.push(value.file);
            }
            break;
        }
      }else{
        state.profile_form[key] = value;
      }
     },
     /**
      * Set Search Parameters.
      * 
      * @param {Object} state 
      * @param {*} param1 
      */
     [SET_SEARCH_PARAMETERS](state, {
       key,
       value
     }) {
       let newKey = (key == "roles") ? 'role' : key;
       state.search.query[newKey] = value;
     }, 
     /**
     * Clear Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */

     [CLEAR_SEARCH_PARAMETERS](state) {
         state.search.query.id = '';
         state.search.query.full_name = '';
         state.search.query.email = '';
         state.search.query.role = '';
     },
};