import template from 'lodash/fp/template';
import {
    FETCH_HOTELS,
    FETCH_HOTEL_DETAILS,
    UPDATE_HOTEL,
    DELETE_HOTEL,
    STORE_HOTEL
} from './api';

import axios from 'axios';

/**
 * Fetch hotels
 */
export function fetchHotels(query) {
    return axios.get(template(FETCH_HOTELS)({ query }));
}

/**
 * Store hotel
 */
export function storeHotel(data) {
    return axios.post(STORE_HOTEL, data);
}

/**
 * Get hotel by Id
 */
export const fetchHotelById = (id) => axios.get(template(FETCH_HOTEL_DETAILS)({ id }));

/**
 * Update Hotel.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHotel = (id, data) => axios.patch(template(UPDATE_HOTEL)({ id }), data);

/**
 * Delete Hotel Data
 * 
 * @param {Integer} id 
 */
export const deleteHotel = (id) => axios.delete(template(DELETE_HOTEL)({ id }));