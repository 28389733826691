
/**
 * Change data to dropdown format.
 * 
 * @param {Array} data 
 * @param {String} label 
 * @param {String} value 
 */
export const changeDataToDropDownFormat = 
    (array = [], label = 'name', value = 'id') => {
    let updated_data = [];
    array.map((data) =>  {
        updated_data.push({
            label: data[label],
            value: data[value]
        });
    });

    return updated_data;
}

/**
 * Hard redirect to the given location.
 *
 * @param {string} location
 */
export const redirect = (location) => {
    window && window.location.assign(location);
  };
  