import {
    SET_VENDORS_DROPDOWN
} from './mutation-type';

import {fetchVendors} from '../../services';
import {changeDataToDropDownFormat} from '../../utils'

const state = {
    vendors_dropdown: [],
};

const getters = {
    fetchVendorsDropdown : (state) => state.vendors_dropdown,
};

const actions = {
    fetchAndSetVendorsDropdown({ commit }) {
        fetchVendors().then((response) => {
            commit(SET_VENDORS_DROPDOWN, changeDataToDropDownFormat(response.data.data, 'full_name'));
        });
    }
};

const mutations = {
    [SET_VENDORS_DROPDOWN](state, records ) {
        state.vendors_dropdown = records;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
