
/**
 * Get list of homestay regions
 * 
 * @param {object} state 
 */
export const getHomestayRegions = (state) => state.homestay_regions;

export const getFormObj = (state) => state.form;

export const getHomestayRegionsDropdown = (state) => state.homestay_regions_dropdown;

export const getImagePreview = (state) => state.image_preview;

export const getSearchObj = (state) => state.search;