import template from "lodash/fp/template";
import { FETCH_BOOKINGS, 
  FETCH_BOOKING_DETAILS, DELETE_BOOKING, CANCEL_BOOKING } from "./api";

import axios from "axios";

/**
 * Fetch Bookings
 */
export const fetchBookings = (query) =>
  axios.get(template(FETCH_BOOKINGS)({ query }));

/**
 * Fetch booking detail by id.
 *
 * @param {Integer} id
 */
export const fetchBookingById = (id) => axios.get(template(FETCH_BOOKING_DETAILS)({ id }));


/**
 * Delete Booking Data
 * 
 * @param {Integer} id 
 */
export const deleteBooking = (id) => axios.delete(template(DELETE_BOOKING)({ id }));

/**
 * Cancel Booking Data
 * 
 * @param {Integer} id 
 */
export const cancelBooking = (id, data) => axios.patch(template(CANCEL_BOOKING)({ id }), data);