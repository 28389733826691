import {
    SET_BOOKINGS,
    SET_BOOKING,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
    [SET_BOOKINGS](state, records ) {
        state.bookings.data = records.data;
        state.bookings.pagination.last_page = records.meta.last_page;
        state.bookings.pagination.per_page = records.meta.per_page;
        state.bookings.pagination.current_page = records.meta.current_page;
    },

    /**
     * Set Search Parameters.
     * 
     * @param {Object} state 
     * @param {*} param1 
     */
    [SET_SEARCH_PARAMETERS](state, {
      key,
      value
    }) {
      state.search.query[key] = value;
    }, 

    /**
    * Clear Search Parameters.
    * 
    * @param {Object} state 
    * @param {*} param1 
    */

    [CLEAR_SEARCH_PARAMETERS](state) {
        state.search.query.id = '';
        state.search.query.status = '';
        state.search.query.user = '';
        state.search.query.service_type = '';
        state.search.query.service_name = '';
    },

    [SET_BOOKING](state, records) { 
      state.booking = records.data;
    },
};