import template from 'lodash/fp/template';
import {
    FETCH_PAGES,
    FETCH_PARENT_PAGES,
    STORE_PAGE,
    FETCH_PAGE_DETAILS,
    UPDATE_PAGE,
    DELETE_PAGE,
    DELETE_PAGE_IMAGE,
} from './api';

import axios from 'axios';

// eslint-disable-next-line no-unused-vars
const config = { headers: { "Content-Type": "multipart/form-data" } };

/**
 * Fetch Pages
 */
export const fetchPages = (query) => axios.get(template(FETCH_PAGES)({ query }));

/**
 * Fetch Pages
 */
export const fetchParentPages = () => axios.get(FETCH_PARENT_PAGES);


/**
 * Store package
 */
export const storePage = (data) => axios.post(STORE_PAGE, data);

/**
 * Fetch Page by pageId.
 *
 * @param {Integer} pageId
 */
export const fetchPageById = (pageId) => axios.get(template(FETCH_PAGE_DETAILS)({ pageId }));

/**
 * Update Page region.
 *
 * @param {*} id
 * @param {*} data
 */
export const updatePage = (id, data) => axios.patch(template(UPDATE_PAGE)({ id }), data);

/**
 * Delete Page Data
 *
 * @param {Integer} id
 */
export const deletePage = (id) => axios.delete(template(DELETE_PAGE)({ id }));

/**
 * Delete Page Image
 *
 * @param {Integer} id
 */

export const deletePageImage = (id) => axios.delete(template(DELETE_PAGE_IMAGE)({ id }));
