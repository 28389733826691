import template from 'lodash/fp/template';
import {
    STORE_HOTEL_ROOM,
    FETCH_HOTEL_ROOM_DETAILS,
    UPDATE_HOTEL_ROOM,
    DELETE_HOTEL_ROOM,
    DELETE_HOTEL_ROOM_IMAGE,
} from './api';


import axios from 'axios';



/**
 * Store Hotel Rooms
 */
export const storeHotelRoom = (hotelId, data) => axios.post(template(STORE_HOTEL_ROOM)({ hotelId }), data);

/**
 * Delete Hotel Room Data
 * 
 * @param {Integer} id 
 */
export const deleteHotelRoom = (hotelId, roomId) => axios.delete(template(DELETE_HOTEL_ROOM)({ hotelId, roomId }));



/**
 * Fetch Hotel Rooms by id.
 * 
 * @param {Integer} id 
 */
export const fetchHotelRoomById = (hotelId, roomId) => axios.get(template(FETCH_HOTEL_ROOM_DETAILS)({ hotelId, roomId }));

/**
 * Update Hotel rooms.
 * 
 * @param {*} id 
 * @param {*} data 
 */
export const updateHotelRoom = (hotelId, roomId, data) => axios.patch(template(UPDATE_HOTEL_ROOM)({ hotelId, roomId }), data);


/**
 * Delete Hotel room Image
 * 
 * @param {Integer} id 
 */

export const deleteHotelRoomImage = (id) => axios.delete(template(DELETE_HOTEL_ROOM_IMAGE)({ id }));