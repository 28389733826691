
export const SET_PAGES = 'SET_PAGES';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_PAGE_FORM = 'SET_PAGE_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_DROPDOWN_FORM = 'UPDATE_DROPDOWN_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';

export const SET_PARENT_PAGE_DROPDOWN = 'SET_PARENT_PAGE_DROPDOWN';
