import {
  SET_HOTEL_REGIONS,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_HOTEL_REGION_FORM,
  SET_HOTEL_REGIONS_DROPDOWN,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS
} from '../mutation-types';

export default {
  [SET_HOTEL_REGIONS](state, records) {
    state.hotel_regions.data = records.data;
    state.hotel_regions.pagination.last_page = records.meta.last_page;
    state.hotel_regions.pagination.per_page = records.meta.per_page;
    state.hotel_regions.pagination.current_page = records.meta.current_page;
  },

  [CLEAR_FORM](state) {
    state.form.name = '';
    state.form.slug = '';
    state.form.alt_image = '';
    state.form.description = '';
    state.form.display_home = false;
    state.form.status = false;
    state.form.images = {};
    state.form.meta_page_title = '';
    state.form.meta_keywords = '';
    state.form.meta_description = '';
  },

  [SET_HOTEL_REGION_FORM](state, records) {
    state.form.name = records.name;
    state.form.slug = records.slug;
    state.form.alt_image = records.alt_image;
    state.form.description = records.description;
    state.form.display_home = records.display_home;
    state.form.status = records.status;
    state.form.meta_page_title = records.meta_tag.title;
    state.form.meta_keywords = records.meta_tag.keywords;
    state.form.meta_description = records.meta_tag.description;
    state.image_preview = (records.images != null) ? records.images[0].url : '';

  },

  [SET_HOTEL_REGIONS_DROPDOWN](state, records) {
    state.hotel_regions_dropdown = records
  },

  /**
   * Update Form.
   * 
   * @param {Object} state 
   * @param {*} param1 
   */
  [UPDATE_FORM](state, {
    key,
    value
  }) {
    state.form[key] = value;
  },

  /**
   * Set Search Parameters.
   * 
   * @param {Object} state 
   * @param {*} param1 
   */
  [SET_SEARCH_PARAMETERS](state, {
    key,
    value
  }) {
    state.search.query[key] = value;
  },

  /**
  * Clear Search Parameters.
  * 
  * @param {Object} state 
  * @param {*} param1 
  */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = '';
    state.search.query.name = '';
  },
};