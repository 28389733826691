
/**
 * Get list of hotels
 * 
 * @param {object} state 
 */
export const getHotels = (state) => {
    return state.hotels;
}

/**
 * Get list of activities dropdown
 * 
 * @param {Object} state 
 */
export const getActivitiesDropdown = (state) => {
    return state.activities_dropdown;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}